<template lang="pug">
    .cookies-popup(v-if="show")
        .title We use cookies!
        FormButton.link(href="/cookies" type="a" target="_blank") Read more
        FormButton(@click="close") I agree
</template>

<script>
    import FormButton from "~/components/FormButton";

    export default {
        name: "Cookies",
        components: {
            FormButton
        },
        data() {
            return {
                show: false,
                details: false
            }
        },
        methods: {
            close() {
                this.show = false;
                this.$cookies.set("confirm-cookies", 1, {maxAge: 365 * 24 * 3600})
            }
        },
        mounted() {
            if (process.browser && !navigator.userAgent.includes("Chrome-Lighthouse") && !this.$cookies.get("confirm-cookies")) this.show = true
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .cookies-popup
        position fixed
        bottom 0
        left 0
        width 100%
        background #fff
        box-shadow 0 20px 50px rgba(0, 36, 51, .3)
        padding 10px
        display flex
        flex-wrap wrap
        justify-content space-around
        z-index 100
        .title
            width 100%
            text-align center
            margin-bottom 10px
        .btn
            width auto
            background dark
            min-height 30px
            &.link
                background none
                color dark
        & + main footer
            margin-bottom 70px
        & + main .chrome-popup
            bottom 70px

    @media screen and (min-width: tablet-width)
        .cookies-popup
            flex-wrap nowrap
            justify-content center
            align-items center
            .title
                width auto
                margin 0
            .btn
                height 30px
                margin-left 20px
            & + main footer
                margin-bottom 40px
            & + main .chrome-popup
                bottom 70px
</style>
