<template lang="pug">
    main
        header.blog-index
            .wrapper
                h1 Blog about accelerating websites
                BlogPostList(count=10)
        TheFooter
</template>

<script>
    import TheFooter from '~/components/TheFooter'
    import BlogPostList from "~/components/BlogPostList";

    export default {
        components: {
            BlogPostList,
            TheFooter
        },
        httpHeaders: () => ({
            'Last-Modified': new Date().toGMTString()
        }),
        data() {
            return {
                text: ''
            }
        },
        async fetch({store}) {
            await store.dispatch("GET_BLOG_LIST");
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

</style>
