<template lang="pug">
    .stat-champions(:class="{open}")
        .wrapper
            .title(@click="open = !open") Rating leaders
            .list
                .item(v-for="item in list")
                    a(:href="item.item.url" target="_blank" rel="nofollow") {{ item.item.info.title }}
                    .achieves
                        .achieve(v-for="achieve in item.achieves" @click="switch_to(achieve)")
                            img(:src="require('~/assets/images/medal-1.svg')" v-if="achieve.place === 1" alt="1")
                            img(:src="require('~/assets/images/medal-2.svg')" v-else-if="achieve.place === 2" alt="2")
                            img(:src="require('~/assets/images/medal-3.svg')" v-else alt="3")
                            ToolTip {{ get_title(achieve) }}
</template>

<script>
    import ToolTip from "~/components/ToolTip";
    import elementOffset from "~/plugins/elementOffset";

    export default {
        name: "TheChampions",
        components: {
            ToolTip
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            get_category(type="rating", device="mobile", page="main") {
                let list = this.$store.state.data
                  .map(item => Object.assign({}, item))
                  .filter(item => item.data && item.data[page] && item.data[page][device]);

                list = list.sort((a, b) => {
                    let a_value = a.data[page][device][type];
                    let b_value = b.data[page][device][type];
                    let sort = 0;
                    if (a_value > b_value) sort = -1;
                    if (a_value < b_value) sort = 1;
                    switch (type) {
                        case "html_load_time":
                        case "fully_loaded_time":
                        case "page_bytes":
                        case "page_elements":
                            sort *= -1
                    }
                    return sort;
                });
                list = list.slice(0, 3);

                for (let i = 0; i < list.length; i++) {
                    list[i].url = `http${list[i].data.main[device].ssl ? "s" : ""}://${list[i].url}`;
                    list[i] = {
                        score: 3 - i,
                        place: i + 1,
                        type,
                        device,
                        page,
                        item: list[i]
                    }
                }

                return list
            },
            get_title(achieve) {
                let title = `${achieve.place} место по `;
                switch (achieve.type) {
                    case "rating":
                        title += "rating";
                        break;
                    case "fully_loaded_time":
                        title += "download speeds";
                        break;
                    case "page_bytes":
                        title += "page weight";
                        break;
                    case "google_pagespeed":
                        title += "Google PageSpeed Insights";
                        break;
                    case "page_elements":
                        title += "number of requests";
                        break;
                }
                if (achieve.type !== "rating") title += ` на ${achieve.device === "desktop" ? "PC" : "smartphone"} (${achieve.page === "main" ? "Home" : this.$store.state.page_data.rating.second_page.title})`;
                return title
            },
            switch_to(achieve) {
                this.$store.commit(`set_mode_${achieve.device}`);
                this.$store.commit(`set_page_${achieve.page}`);
                this.$store.commit(`set_current_${achieve.type}`);
                this.$store.commit("set_sort", "value");
                this.$store.commit("set_order", -1);
                const el = document.getElementById("stat-list-items");
                if (el) {
                    const offset = elementOffset(el);
                    window.scrollTo(0, offset.top)
                }
            }
        },
        computed: {
            list() {
                let list = this.get_category();

                list.push(...this.get_category("fully_loaded_time"));
                list.push(...this.get_category("page_bytes"));
                list.push(...this.get_category("page_elements"));
                list.push(...this.get_category("google_pagespeed"));

                list.push(...this.get_category("fully_loaded_time", "mobile", "second"));
                list.push(...this.get_category("page_bytes", "mobile", "second"));
                list.push(...this.get_category("page_elements", "mobile", "second"));
                list.push(...this.get_category("google_pagespeed", "mobile", "second"));

                const items = {};

                for (let item of list) {
                    if (!items[item.item._id]) items[item.item._id] = {score: 0, item: item.item, achieves: []};
                    items[item.item._id].score += item.score;
                    items[item.item._id].achieves.push({place: item.place, type: item.type, device: item.device, page: item.page});
                }

                list = Object.keys(items).map(id => items[id]);
                list = list.sort((a, b) => a.score > b.score ? -1 : (a.score < b.score ? 1 : (a.achieves.length > b.achieves.length ? -1 : 1)));

                list = list.slice(0, 3);
                list = list.map(item => {
                    item.achieves = item.achieves.sort((a, b) => a.place > b.place ? 1 : -1);
                    return item
                });

                return list
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .stat-champions
        background #E7E7E7
        padding 10px
        .title
            font-size 25px
            font-weight bold
            padding 10px
            text-align center
            display flex
            align-items center
            justify-content center
            &::after
                content ""
                border-top 8px solid
                border-left 8px solid transparent
                border-right 8px solid transparent
                border-bottom 0
                width 0
                height 0
                margin 3px 0 0 10px
        .list
            display none
            .item
                margin-bottom 10px
                a
                    color inherit
                    text-decoration inherit
                .achieves
                    margin-top 5px
                    display flex
                    flex-wrap wrap
                    .achieve
                        width 50px
                        margin-right 10px
                        display flex
                        align-items center
                        img
                            display block
                            width 100%
        &.open
            .title
                &::after
                    border-bottom 8px solid
                    border-top 0
            .list
                display block

    @media screen and (min-width: desktop-width)
        .stat-champions
            padding 20px 0
            .title
                font-size 40px
                margin-bottom 20px
                &::after
                    display none
            .wrapper
                text-align center
            .list
                display inline-block
                .item
                    display flex
                    align-items center
                    .achieves
                        margin-top 0
                        margin-left 20px
                        .achieve
                            width 35px
                            cursor pointer
                            transition .2s
                            position relative
                            &:hover
                                transform scale(1.2)
                                z-index 5
                            .tool-tip
                                position absolute
                                top 0
                                left 0
                                width 100%
                                height 100%
                                margin 0
                                border 0
                                font-size 0
                                .popup-tooltip
                                    top 28px
                                    left 50%
                                    &::before
                                    &::after
                                        margin-left -5px
            &.open
                .list
                    display inline-block
</style>
