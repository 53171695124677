<template lang="pug">
    .block.stat-block(v-bind:class="{ loading: loading }")
        .progress
        .wrapper
            h2.title In real time
            .stats
                .stat
                    .desc today
                    // animber.ttl(:number="todayTests")
                    .ttl {{ new Intl.NumberFormat('ru-RU').format(todayTests) }}
                    .desc {{ declOfNum(todayTests, ['Check', 'checks', 'Checks']) }}
                .stat
                    .desc for the year
                    // animber.ttl(:number="yearTests")
                    .ttl {{ new Intl.NumberFormat('ru-RU').format(yearTests) }}
                    .desc {{ declOfNum(yearTests, ['Check', 'checks', 'Checks']) }}
                .stat
                    .desc for the year
                    // animber.ttl(:number="yearSpeedup")
                    .ttl {{ new Intl.NumberFormat('ru-RU').format(yearSpeedup) }}
                    .desc {{ declOfNum(yearSpeedup, ['site is accelerated', 'the site is accelerated', 'sites accelerated']) }}
                .stat
                    .desc for the year
                    // animber.ttl(:number="yearConsult")
                    .ttl {{ new Intl.NumberFormat('ru-RU').format(yearConsult) }}
                    .desc {{ declOfNum(yearConsult, ['The consultation was provided', 'consultations were provided', 'consultations were provided']) }}
                .stat
                    .desc we spent
                    // animber.ttl(:number="live")
                    .ttl {{ new Intl.NumberFormat('ru-RU').format(live) }}
                    .desc {{ declOfNum(live, ['hour', 'hours', 'hours']) }} live
</template>

<script>
    import axios from "axios"
    import config from "~/config"

    const STAT_URL = `${config.API}/stat`;

    export default {
        name: "stat",
        components: {
        },
        data() {
            return {
                todayTests: 0,
                yearTests: 0,
                yearSpeedup: 0,
                yearConsult: 0,
                live: 0,
                loading: false
            }
        },
        methods: {
            declOfNum(number, titles) {
                let cases = [2, 0, 1, 1, 1, 2];
                return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
            }
        },
        mounted() {
            this.todayTests = this.$store.state.statistics.check.today;
            this.yearTests = this.$store.state.statistics.check.all;
            this.yearSpeedup = this.$store.state.statistics.speedup.all;
            this.yearConsult = this.$store.state.statistics.consult.all;
            this.live = this.$store.state.statistics.live.hour;
            if(process.browser && !navigator.userAgent.includes("Chrome-Lighthouse")) {
                setInterval(() => {
                    return axios.get(STAT_URL + '?rnd=' + Math.floor(Date.now() / 1000)).then((response) => {
                        this.loading = false;
                        this.todayTests = response.data.check.today;
                        this.yearTests = response.data.check.all;
                        this.yearSpeedup = response.data.speedup.all;
                        this.yearConsult = response.data.consult.all;
                        this.live = response.data.live.hour;
                        setTimeout(() => {
                            this.loading = true;
                        }, 500);
                    });
                }, 10000);
                axios.get(STAT_URL + '?rnd=' + Math.floor(Date.now() / 1000)).then((response) => {
                    this.todayTests = response.data.check.today;
                    this.yearTests = response.data.check.all;
                    this.yearSpeedup = response.data.speedup.all;
                    this.yearConsult = response.data.consult.all;
                    this.live = response.data.live.hour;
                });
                setTimeout(() => {
                    this.loading = true;
                }, 500);
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"
    .stat-block
        background #444 url("~assets/images/spinner.svg") no-repeat 20px 20px / 20px ;
        color #fff
        position relative
        transition all .2s ease-in-out
        .progress
            width 0
            height 5px
            background-color purple
            bottom -2px
            left 0
            position absolute
            border-radius 0 3px 3px 0
        .title
            margin-bottom 50px
        .stats
            display flex
            justify-content space-between
            flex-wrap wrap
            .stat
                width 100%
                margin 20px 0
                .ttl
                    font-size 36px
                    font-weight bold
        &.loading
            background #222
            background-position 20px 20px
            background-size 20px
            .progress
                width 100%
                transition all 9.5s linear

    @media screen and (min-width: tablet-width)
        .stat-block
            .stats
                flex-wrap nowrap
                .stat
                    width auto
                    margin 0 20px
</style>
