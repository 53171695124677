<template lang="pug">
    .competitions(v-if="competitionExist")
        h2 Check the speed of competitors sites
        .item(v-for="competition in cmData") {{ competition }}
            a(:href="`https://load.express/?scan_url=${competition}&server=${server}`" target="_blank") check
        p.source {{ `Data provided ` }}
            a(href="https://metrica.guru" target="_blank" rel="noopener") metrica.guru
</template>
<script>
    import axios from "axios";
    export default {
        name: "competitions",
        props: {
            url: {
                type: String,
                required: true
            },
            server: {
                type: String,
                required: true
            }
        },
        components: {

        },
        data() {
            return {
                cmData: '',
                competitionExist: false
            }
        },
        mounted() {
            axios.get('https://app.loading.express/competitions?url='+this.url).then(({data}) => {
                if(data && data.data && data.data.length > 0) {
                    this.cmData = data.data;
                    this.competitionExist = true;
                }
            }).catch(error => {
                console.log('crux dosen\'t collected: '+error);
            });
        }
    }
</script>

<style lang="stylus">
@require "~assets/style/VAR.styl"
.competitions
    margin 20px 0
    background #efefef
    border-radius 3px
    padding 10px
    .source
        margin 0
        text-align left
        a
            color blue
    h2
        margin-bottom 20px
    .item
        padding 10px
        margin 10px 0
        background #fcfcfc
        border-radius 3px
        a
            color purple
            display block
        &:last-child
            margin-bottom 0
@media screen and (min-width: tablet-width)
    .competitions
        .source
            text-align right
        .item
            a
                margin-left 10px
                display inline
</style>
