<template lang="pug">
    main
        chromeEx
        topPanel
        TheCheck
        ScanScreen
        stat
        FAQ
        about
        TheYouTube
        OrderForm(title="Order Website Acceleration" button="Order" goal="lead-speed-up" site)
        LazyHydrate(ssr-only)
            TheFooter
</template>

<script>
    import TheCheck from "~/components/TheCheck";
    import FAQ from "~/components/FAQ";
    import about from "~/components/about";
    import TheFooter from "~/components/TheFooter";
    import TheYouTube from "~/components/TheYouTube";
    import ScanScreen from "~/components/ScanScreen";
    import OrderForm from "~/components/OrderForm";
    import topPanel from "~/components/topPanel";
    import LazyHydrate from 'vue-lazy-hydration';
    import chromeEx from '~/components/chromeEx';
    import stat from '~/components/stat';

    // const TheCheck = () => import("~/components/TheCheck");
    // const FAQ  = () => import("~/components/FAQ");
    // const about = () => import("~/components/about");
    // const reviews = () => import("~/components/reviews");
    // const TheFooter = () => import("~/components/TheFooter");
    // const TheYouTube = () => import("~/components/TheYouTube");
    // const ScanScreen = () => import("~/components/ScanScreen");
    // const OrderForm = () => import("~/components/OrderForm");
    // const TheRatingsList = () => import("~/components/TheRatingsList");
    // const topPanel = () => import("~/components/topPanel");
    // const LazyHydrate = () => import('vue-lazy-hydration');
    // const chromeEx = () => import('~/components/chromeEx');
    // const stat = () => import('~/components/stat');

    export default {
        name: "index",
        components: {
            LazyHydrate,
            FAQ,
            stat,
            about,
            OrderForm,
            ScanScreen,
            TheYouTube,
            TheCheck,
            TheFooter,
            topPanel,
            chromeEx
        },
        httpHeaders: () => ({
            'Last-Modified': new Date().toGMTString()
        }),
        head() {
            return {
                title: 'Check the speed of the site online. The speed of loading sites.',
                meta: [
                    {hid: "description", name: "description", content: 'Check the loading speed of the site and pages. What affects the speed of the site. How to analyze page speed tests? Get a free consultation!'},
                    {hid: "og:locale", property: "og:locale", content: "en"},
                    {hid: "og:title", property: "og:title", content: 'Check site load speed online'},
                    {hid: "og:description", property: "og:description", content: 'Check the loading speed of your site and pages. What affects the speed of the site. How to analyze page speed tests?'},
                    {hid: "og:image", property: "og:image", content: "https://load.express/og-en.jpg"},
                    {hid: "twitter:image", property: "twitter:image", content: "https://load.express/og-en.jpg"},
                    {hid: "twitter:title", property: "twitter:title", content: "Check the loading speed of the site online. The speed of loading sites."},
                    {hid: "twitter:description", property: "twitter:description", content: "Check the loading speed of the site and pages. What affects the speed of the site. How to analyze page speed tests? Get a free consultation!"},
                ],
                link: [
                    {rel: "alternate", hreflang: "x-default", href: "https://load.express/"},
                    {rel: "alternate", hreflang: "ru-ru", href: "https://loading.express/"},
                    {rel: "alternate", hreflang: "en-us", href: "https://load.express"}

                ]
            }
        },
        async fetch({store}) {
            await store.dispatch("GET_RATINGS_LIST");
            await store.dispatch("GET_YOUTUBE_LIST");
            await store.dispatch("GET_STATISTICS");
        }
    }
</script>
