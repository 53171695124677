<template lang="pug">
    .core-web-vitals(v-if="wvData")
        .liveTitle Core Web Vitals
        .wv-data
            .wv-fcp.item(v-if="wvData.first_contentful_paint")
                .ttl(v-bind:class="{ good:  wvData.first_contentful_paint.percentiles.p75 <= 1000, medium: wvData.first_contentful_paint.percentiles.p75 > 1000 && wvData.first_contentful_paint.percentiles.p75 <= 3000, bad: wvData.first_contentful_paint.percentiles.p75 > 3000}")
                    | FCP
                    .val {{ Math.round(wvData.first_contentful_paint.percentiles.p75/100)/10 }} sec.
                .hist
                    .good(:style="`width:${Math.round(wvData.first_contentful_paint.histogram[0].density*100)}%`") {{ Math.round(wvData.first_contentful_paint.histogram[0].density*100) }}%
                    .medium(:style="`width:${Math.round(wvData.first_contentful_paint.histogram[1].density*100)}%`") {{ Math.round(wvData.first_contentful_paint.histogram[1].density*100) }}%
                    .bad(:style="`width:${Math.round(wvData.first_contentful_paint.histogram[2].density*100)}%`") {{ Math.round(wvData.first_contentful_paint.histogram[2].density*100) }}%
            .wv-lcp.item(v-if="wvData.largest_contentful_paint")
                .ttl(v-bind:class="{ good:  wvData.largest_contentful_paint.percentiles.p75 <= 2500, medium: wvData.largest_contentful_paint.percentiles.p75 > 2500 && wvData.largest_contentful_paint.percentiles.p75 <= 4000, bad: wvData.largest_contentful_paint.percentiles.p75 > 4000}")
                    | LCP
                    .val {{ Math.round(wvData.largest_contentful_paint.percentiles.p75/100)/10 }} sec.
                .hist
                    .good(:style="`width:${Math.round(wvData.largest_contentful_paint.histogram[0].density*100)}%`") {{ Math.round(wvData.largest_contentful_paint.histogram[0].density*100) }}%
                    .medium(:style="`width:${Math.round(wvData.largest_contentful_paint.histogram[1].density*100)}%`") {{ Math.round(wvData.largest_contentful_paint.histogram[1].density*100) }}%
                    .bad(:style="`width:${Math.round(wvData.largest_contentful_paint.histogram[2].density*100)}%`") {{ Math.round(wvData.largest_contentful_paint.histogram[2].density*100) }}%
            .wv-fid.item(v-if="wvData.first_input_delay")
                .ttl(v-bind:class="{ good:  wvData.first_input_delay.percentiles.p75 <= 100, medium: wvData.first_input_delay.percentiles.p75 > 100 && wvData.first_input_delay.percentiles.p75 <= 300, bad: wvData.first_input_delay.percentiles.p75 > 300}")
                    | FID
                    .val {{ wvData.first_input_delay.percentiles.p75 }} ms
                .hist
                    .good(:style="`width:${Math.round(wvData.first_input_delay.histogram[0].density*100)}%`") {{ Math.round(wvData.first_input_delay.histogram[0].density*100) }}%
                    .medium(:style="`width:${Math.round(wvData.first_input_delay.histogram[1].density*100)}%`") {{ Math.round(wvData.first_input_delay.histogram[1].density*100) }}%
                    .bad(:style="`width:${Math.round(wvData.first_input_delay.histogram[2].density*100)}%`") {{ Math.round(wvData.first_input_delay.histogram[2].density*100) }}%
            .wv.cls.item(v-if="wvData.cumulative_layout_shift")
                .ttl(v-bind:class="{ good:  wvData.cumulative_layout_shift.percentiles.p75 <= 0.1, medium: wvData.cumulative_layout_shift.percentiles.p75 > 0.1 && wvData.cumulative_layout_shift.percentiles.p75 <= 0.25, bad: wvData.cumulative_layout_shift.percentiles.p75 > 0.25}")
                    | CLS
                    .val {{ wvData.cumulative_layout_shift.percentiles.p75 }}
                .hist
                    .good(:style="`width:${Math.round(wvData.cumulative_layout_shift.histogram[0].density*100)}%`") {{ Math.round(wvData.cumulative_layout_shift.histogram[0].density*100) }}%
                    .medium(:style="`width:${Math.round(wvData.cumulative_layout_shift.histogram[1].density*100)}%`") {{ Math.round(wvData.cumulative_layout_shift.histogram[1].density*100) }}%
                    .bad(:style="`width:${Math.round(wvData.cumulative_layout_shift.histogram[2].density*100)}%`") {{ Math.round(wvData.cumulative_layout_shift.histogram[2].density*100) }}%
</template>
<style lang="stylus">
.core-web-vitals
    margin-bottom 20px
    .liveTitle
        font-size: 22px
        font-weight bold
        a
            color #01A0E2
            margin-left 5px
    .ttl
        font-size 14px
        font-weight bold
        display flex
        justify-content space-between
    .good
        color #52b94f
    .medium
        color #e89438
    .bad
        color #d31e53
    .item
        padding 10px
    .hist
        display flex
        height 30px
        overflow hidden
        border-radius 3px
        font-size 14px
        .good
            background #52b94f
        .medium
            background #e89438
        .bad
            background #d31e53
        &>*
            color #fff
            display flex
            align-items center
            justify-content flex-start
            padding 5px 0 5px 5px
@media screen and (min-width: 720px)
    .core-web-vitals
        .item
            width 50%
        .wv-data
            display flex
            flex-wrap wrap
</style>
<script>
import axios from "axios";

export default {
    props: {
        url: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            wvData: null
        };
    },
    computed: { },
    mounted() {
        axios.post('https://chromeuxreport.googleapis.com/v1/records:queryRecord?key=AIzaSyBd72Td6DDByzP3U5WK28mg3eVXB7jxM10', {origin: this.url}).then(({data}) => {
            this.wvData = data.record.metrics;
        }).catch(error => {
            console.log('crux dosen\'t collected: '+error);
        });
    }
}
</script>