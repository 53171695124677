// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/whatsapp.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/images/viber.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/images/skype.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/images/fb-messanger.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("assets/images/tg.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("assets/images/vk.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, ".contact{text-align:center;margin-top:30px}.contact .messenger{display:flex;justify-content:center}.contact .messenger a{width:40px;min-width:40px;max-width:40px;height:40px!important;border-radius:50%;background-position:50%;background-repeat:no-repeat;background-size:20px;margin:5px;padding:0}.contact .messenger a.whatsapp{background-color:#25d366;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.contact .messenger a.viber{background-color:#665cac;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.contact .messenger a.skype{background-color:#00aff0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.contact .messenger a.fb-messanger{background-color:#1a9bfc;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.contact .messenger a.telegram{background-color:#08c;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.contact .messenger a.vk{background-color:#45668e;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}.contact .messenger a:hover{opacity:.9;background-size:20px;background-position:50%;background-repeat:no-repeat}.contact .messenger a:active{transform:scale(.98)}", ""]);
// Exports
module.exports = exports;
