// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/sky-bottom.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/images/orbit.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".order-form-block{background:#1b2638 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;color:#fff;background-size:cover}.order-form-block .form{background:#335766;box-shadow:0 20px 50px rgba(0,36,51,.3);border-radius:3px;padding:20px;max-width:370px;margin:30px auto}.order-form-block .form .title{text-align:center;margin-bottom:20px}.order-form-block .form label{position:relative}.order-form-block .form label span{display:block;width:10px;height:10px;font-size:0;position:absolute}.order-form-block .form-item{width:100%;display:block;border:none;box-sizing:border-box;border-radius:3px;height:40px;padding:10px 20px 10px 30px;line-height:20px;margin-bottom:20px;outline:none;font-size:18px}.order-form-block .form-item:focus{border-color:#01a0e2}.order-form-block textarea.form-item{height:auto}.order-form-block .fz-block{text-align:left}.success-popup .image{width:260px;height:260px;overflow:hidden;border-radius:50%;margin:10px auto 0;background:#1b2638 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:600px;background-position:50%;position:relative}.success-popup .image .rocket{position:absolute;left:50%;top:30px;width:110px;margin-left:-55px;z-index:3}.success-popup .image .cloud{position:absolute}.success-popup .image .cloud.front{bottom:0;left:0;width:550px;z-index:4}.success-popup .image .cloud.middle{bottom:0;left:10px;width:480px;z-index:2}.success-popup .image .cloud.back{bottom:0;left:-20px;width:400px;z-index:1}@media screen and (min-width:1020px){.order-form-block{padding:0 20px!important}.order-form-block .wrapper{padding:80px 0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:cover}.order-form-block .form{padding:40px 30px;width:370px;margin:auto}.order-form-block .form .title{font-size:22px}}", ""]);
// Exports
module.exports = exports;
