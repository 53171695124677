<template lang="pug">
    .contact
        p Or email us on any of our messengers
        .messenger
            a.whatsapp(href="whatsapp://send?text=Привет&phone=79111184858" target="_blank")
            a.fb-messanger(href="https://m.me/checkspeedsite" target="_blank")
            a.telegram(href="tg://resolve?domain=axdigital" target="_blank")
            a.vk(href="https://vk.me/ax.digital" target="_blank")
</template>

<script>
    export default {
        name: "TheContact"
    }
</script>

<style lang="stylus">
    .contact
        text-align center
        margin-top 30px
        .messenger
            display flex
            justify-content center
            a
                width 40px
                min-width 40px
                max-width 40px
                height 40px!important
                border-radius 50%
                background-position center
                background-repeat no-repeat
                background-size 20px
                margin 5px
                padding 0
                &.whatsapp
                    background-color #25D366
                    background-image url("~assets/images/whatsapp.svg")
                &.viber
                    background-color #665CAC
                    background-image url("~assets/images/viber.svg")
                &.skype
                    background-color #00aff0
                    background-image url("~assets/images/skype.svg")
                &.fb-messanger
                    background-color #1a9bfc
                    background-image url("~assets/images/fb-messanger.svg")
                &.telegram
                    background-color #0088cc
                    background-image url("~assets/images/tg.svg")
                &.vk
                    background-color #45668e
                    background-image url("~assets/images/vk.svg")
                &:hover
                    opacity .9
                    background-size 20px
                    background-position center
                    background-repeat no-repeat
                &:active
                    transform scale(.98)
</style>
