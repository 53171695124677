<template lang="pug">
    span.l-img(itemscope itemtype="http://schema.org/ImageObject")
        meta(itemprop="name" :content="alt")
        img(v-if="srcImage" :src="srcImage" :srcset="srcSetImage" :alt="alt" decoding="async" :width="width" :height="height" itemprop="contentUrl")
        img(v-else :src="src" :srcset="require('~/assets/images/transperent.svg')" :data-src="src" :data-srcset="srcset" :alt="alt" :width="width" :height="height" itemprop="contentUrl")
</template>
<style lang="stylus">
    .l-img
        img
            transition all 200ms ease-in-out
            opacity 1
            transform none
            &[data-src]
                opacity 0
                transform scale(.4);
</style>
<script>
    export default {
        props: {
            src: {
                type: String,
                required: true
            },
            srcset: {
                type: String
            },
            width: {
                type: String
            },
            height: {
                type: String
            },
            alt: {
                type: String,
                default: ""
            }
        },
        data: () => ({ observer: null, intersected: false }),
        computed: {
            srcImage() {
                return this.intersected ? this.src : '';
            },
            srcSetImage() {
                return this.intersected ? this.srcset : '';
            }
        },
        mounted() {
            this.observer = new IntersectionObserver(entries => {

                const image = entries[0];
                if (image.isIntersecting) {
                    this.intersected = true;
                    this.observer.disconnect();
                }
            },{
                rootMargin: "0px 0px 500px 0px"
            });
            this.observer.observe(this.$el);
        },
    }
</script>