// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/loading-logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/images/phone.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/images/ru.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".top-block{position:fixed;top:0;left:0;width:100%;height:50px;background:#1b2638;z-index:10}.top-block .wrapper{display:flex;justify-content:space-between;align-items:center;height:50px;padding:0 20px}.top-block .wrapper .logo{display:block;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0/180px;width:100%;height:30px;font-size:0}.top-block .wrapper nav{display:none}.top-block .wrapper a.phone{color:#fff;text-decoration:none;font-size:0;min-width:40px;min-height:50px;display:flex;align-items:center;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 5px/30px}.top-block .wrapper a.lang{min-width:40px;min-height:50px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat 50%/30px;font-size:0;margin-left:10px}.top-block .wrapper .callback-link{display:none}.top-block .title{font-size:25px;margin-bottom:10px}@media screen and (min-width:1020px){.top-block .wrapper .logo{min-width:180px;max-width:180px;cursor:pointer}.top-block .wrapper nav{display:flex;width:100%;justify-content:space-between;margin:0 15px;align-items:stretch}.top-block .wrapper nav a{color:#fff;font-size:14px;text-decoration:none;cursor:pointer;border-bottom:2px solid transparent;padding:16px 15px;border-radius:3px;display:flex;justify-content:center;align-items:center;text-align:center}.top-block .wrapper nav a:hover{border-bottom:2px solid #d52055;background:hsla(0,0%,100%,.1)}.top-block .wrapper .callback-link{cursor:pointer;font-size:14px;border-bottom:1px solid #fff;margin-left:10px;display:block;color:#fff}.top-block .wrapper a.phone{font-size:20px;background:none;width:200px;min-width:180px;padding-left:0}.top-block .title{font-size:40px}}", ""]);
// Exports
module.exports = exports;
