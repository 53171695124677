<template lang="pug">
    .block.order-form-block#order
        .wrapper.wide
            form.form#orderform(@submit.prevent="submit")
                .title {{ title }}
                label
                    span Enter your name
                    input.form-item.name(v-model="form.name" placeholder="Enter your name")
                label
                    span Enter your phone number
                    input.form-item.phone(v-model="form.phone" type="tel" placeholder="Phone number" autocapitalize="off")
                label
                    span Enter your website address
                    input.form-item.url(v-if="site" v-model="form.site" placeholder="Website address" autocapitalize="off")
                label
                    span Enter a promo code for a discount
                    input.form-item.promocode(v-model="form.promocode" placeholder="Discount promo code")
                label
                    span Enter your wishes or suggestions
                    textarea.form-item.message(v-model="form.comment" placeholder="Commentary" rows="5")
                button.btn.send {{ button }}
                fz
        ThePopup.success-popup(v-if="success" @close="success = false")
            .title Thank you for the application!
            .image
                img.rocket(:src="require('~/assets/images/rocket.svg')")
                img.cloud.back(:src="require('~/assets/images/cloud-back.svg')")
                img.cloud.middle(:src="require('~/assets/images/cloud-middle.svg')")
                img.cloud.front(:src="require('~/assets/images/cloud-front.svg')")
</template>

<script>
    import axios from "axios"
    import ThePopup from "~/components/ThePopup";
    import config from "~/config"
    import Fz from "~/components/fz";

    const MAIL_URL = `${config.API}/mail`;

    export default {
        name: "OrderForm",
        props: {
            title: {
                type: String,
                default: "Connect to monitor the loading speed of the site"
            },
            button: {
                type: String,
                default: "Order Inspections"
            },
            site: Boolean,
            goal: String
        },
        components: {
            Fz,
            ThePopup
        },
        data() {
            return {
                form: {
                    name: "",
                    phone: "",
                    comment: "",
                    site: ""
                },
                success: false
            }
        },
        methods: {
            submit() {
                this.success = true;
                if (this.goal) this.$sendGoal(this.goal);
                return axios.post(MAIL_URL, Object.assign(this.form, {
                    mail_subject: this.title,
                    client_id: this.$cookies.get("utmstat_client_id"),
                    current_url: document.location.href
                })).then(() => {
                    this.form.name = "";
                    this.form.phone = "";
                    this.form.promocode = "";
                    this.form.comment = "";
                });
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .order-form-block
        background night-sky url("~assets/images/sky-bottom.svg") no-repeat center
        color #fff
        background-size cover
        .form
            background #335766
            box-shadow 0 20px 50px rgba(0, 36, 51, 0.3)
            border-radius 3px
            padding 20px
            max-width: 370px;
            margin: 30px auto;
            .title
                text-align center
                margin-bottom 20px
            label
                position relative
                span
                    display block
                    width 10px
                    height 10px
                    font-size 0
                    position absolute
        .form-item
            width 100%
            display block
            border none
            box-sizing border-box
            border-radius 3px
            height 40px
            padding 10px 20px 10px 30px
            line-height 20px
            margin-bottom 20px
            outline none
            font-size 18px
            &:focus
                border-color blue
        textarea.form-item
            height auto
        .fz-block
            text-align left

    .success-popup
        .image
            width 260px
            height 260px
            overflow hidden
            border-radius 50%
            margin 10px auto 0
            background night-sky url("~assets/images/sky-bottom.svg")
            background-size 600px
            background-position center
            position relative
            .rocket
                position absolute
                left 50%
                top 30px
                width 110px
                margin-left -55px
                z-index 3
            .cloud
                position absolute
                &.front
                    bottom 0
                    left 0
                    width 550px
                    z-index 4
                &.middle
                    bottom 0
                    left 10px
                    width 480px
                    z-index 2
                &.back
                    bottom 0
                    left -20px
                    width 400px
                    z-index 1

    @media screen and (min-width: desktop-width)
        .order-form-block
            padding 0 20px!important
            .wrapper
                padding 80px 0
                background url("~assets/images/orbit.svg") no-repeat center
                background-size cover
            .form
                padding 40px 30px
                width 370px
                margin auto
                .title
                    font-size 22px
</style>
