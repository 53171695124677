<template lang="pug">
    header.promo
        .wrapper.wide
            .wrapper
                .language#language(v-if="language_changer")
                    .item(v-for="lang in languages_list" :key="lang"
                        :class="{current: language === lang}"
                        :is="language === lang ? 'div' : 'nuxt-link'"
                        :to="`/${lang === 'ru' ? default_language_page : `${lang}.html`}`")
                        img(src="~/assets/images/ru.svg" v-if="lang === 'ru'" alt="ru")
                        img(src="~/assets/images/en.svg" v-if="lang === 'en'" alt="en")

                img.logo(src="~/assets/images/logo.svg")
                .content
                    .text
                        .title {{ text.title }}
                        div(v-html="text.text")
                    img.rocket(src="/images/rocket.svg")
                    FormWrapper.form(method="post" action="/mail" data-goal="forma_top" :language="language")
                        .phone(v-if="text.phone" v-html="text.phone")
                        .title {{ text.form.title }}
                        //input.form-item(name="name" placeholder=header.form.name)
                        input.form-item(name="email" type="email" :placeholder="text.form.email" autocapitalize="off" required)
                        input.form-item(name="site" :placeholder="text.form.site" autocapitalize="off" required)
                        FormButton {{ text.form.button }}
                        fz

            img.cloud.back(src="~/assets/images/cloud-back.svg")
            img.cloud.middle(src="~/assets/images/cloud-middle.svg")
            img.cloud.front(src="~/assets/images/cloud-front.svg")
        ScrollArrow
</template>

<script>
    import FormWrapper from "~/components/FormWrapper"
    import FormButton from "~/components/FormButton"
    import Fz from "~/components/fz";
    import ScrollArrow from '~/components/ScrollArrow'

    export default {
        name: "TheHeader",
        props: {
            language_changer: Boolean,
            language: {
                type: String,
                default: "ru"
            }
        },
        data() {
            return {
                languages_list: ["ru", "en"]
            }
        },
        computed: {
            text() {
                return this.languages[this.language].header
            }
        },
        components: {
          ScrollArrow,
            Fz,
            FormWrapper,
            FormButton
        },
        mounted() {
            for (let input of this.$el.getElementsByTagName("input")) {
                input.setAttribute("autocorrect", "off")
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    header.promo
        padding 20px 0 0
        overflow hidden
        background-color night-sky
        background-image url("~assets/images/stars.svg")
        position relative
        .wrapper.wide
            position relative
        .language
            position absolute
            top 0
            right 10px
            width 32px
            display flex
            flex-direction column
            .item
                order 2
                display block
                width 30px
                height 15px
                margin-bottom 10px
                &.current
                    order 1
                img
                    display block
                    width 100%
        .logo
            width 194px
            display block
            margin 0 auto
        .content
            color #fff
            padding 20px 10px 0
            display flex
            flex-direction column
        .text
            order 1
            .title
                font-size 40px
                line-height 1
                margin-bottom 20px
                &.min
                    font-size 30px
        .form
            order 2
            background #335766
            box-shadow 0 20px 50px rgba(0, 36, 51, 0.3)
            //border-radius 10px
            padding 20px
            //margin-top 60px
            position relative
            max-width 370px
            margin 60px auto 0
            .phone
                color #fff
                position absolute
                width 100%
                height 30px
                top -40px
                left 0
                text-align center
                font-size 24px
                a
                    color inherit
                    text-decoration none
            .title
                text-align center
                margin-bottom 20px
        .rocket
            order 3
            width 50px
            display block
            margin 20px auto 0
        .cloud
            position absolute
            &.back
            &.middle
                display none
            &.front
                bottom 0
                left 0
                width 100%

    @media screen and (min-width: desktop-width)
        header.promo
            padding 50px 20px 0
            .language
                flex-direction row
                width auto
                height 15px
                .item
                    margin-bottom 0
                    margin-right 10px
            .content
                flex-direction row
                justify-content space-between
                min-height 612px
                align-items flex-start
                padding-top 50px
                margin-top 30px
            .rocket
                width 200px
                position absolute
                bottom 0
                left 50%
                margin-left -100px
                z-index 5
            .form
                order 3
                width 370px
                max-width calc(50% - 120px)
                margin 0
                z-index 6
                padding 40px 30px
                .title
                    font-size 22px
                .phone
                    top -70px
                    font-size 30px
            .text
                width 470px
                max-width calc(50% - 120px)
                z-index 6
                .title
                    font-size 60px
                    &.min
                        font-size 40px
            .cloud
                &.front
                    z-index 4
                &.middle
                    display block
                    bottom 0
                    left 20%
                    width 80%
                    z-index 3
                &.back
                    display block
                    bottom 0
                    left 25%
                    width 69%
                    z-index 2
</style>
