import axios from "axios"
import config from "~/config"

export const state = () => ({
    data: [],
    current: "fully_loaded_time",
    mode: "mobile",
    page: "main",
    sort: "value",
    order: -1,
    list_filter: [],
    page_data: {
        meta: {
            title: "",
            description: "",
            slug: "",
            og_image: ""
        },
        header: {
            title: "",
            text: "",
            link_to_data: "",
            link_to_text: ""
        },
        text: {
            before: "",
            after: ""
        },
        rating: {
            title: "",
            category_title: "",
            category_label: "",
            rating_button: "",
            rating_description: "",
            rating_description_short: "",
            main_page: {
                title: "",
                icon: "",
                checked_icon: ""
            },
            second_page: {
                title: "",
                icon: "",
                checked_icon: ""
            },
            category: ""
        }
    },
    cookie: {
        day_scans: 0,
        already_subscribed: false,
        confirmed: false
    },
    scanner: {
        status: null,
        id: null,
        queue: 0,
        result: null,
        site: "",
        email: "",
        phone: "",
        server: "",
    },
    ratings_list: [],
    youtube_list: [],
    reviews_list: [],
    blog_list: [],
    statistics: []
});

export const actions = {
    GET_STAT: ({commit}, category) => new Promise(resolve => {
        return axios.get(`${config.API}/pagespeed`, {params: {category}}).then(({data}) => {
            commit("set_data", data);
            return resolve()
        })
    }),
    SEND_FORM: ({}, {action, data}) => new Promise((resolve, reject) => {
        return axios.post(action, data).then(({data}) => {
            if (data === "OK") return resolve();
            return reject()
        }).catch(reject)
    }),
    GET_PAGE: ({commit}, slug) => new Promise(resolve => {
        return axios.get(`${config.API}/pages`, {params: {slug}}).then(({data}) => {
            commit("set_page_data", data);
            return resolve()
        })
    }),
    GET_RATINGS_LIST: ({commit}, exclude) => new Promise(resolve => {
        return axios.get(`${config.API}/pages`, {params: {exclude}}).then(({data}) => {
            commit("set_ratings_list", data);
            return resolve()
        })
    }),
    GET_YOUTUBE_LIST: ({commit}, exclude) => new Promise(resolve => {
        return axios.get(`${config.API}/youtube`,{params: {exclude}}).then(({data}) => {
            commit("set_youtube_list", data);
            return resolve()
        });
    }),
    GET_STATISTICS: ({commit}, exclude) => new Promise(resolve => {
        return axios.get(`${config.API}/stat`,{params: {exclude}}).then(({data}) => {
            commit("set_statistics", data);
            return resolve()
        });
    })
};

export const mutations = {
    set_data: (state, data) => state.data = data,
    set_page_data: (state, page) => state.page_data = page,
    set_mode_desktop: state => state.mode = "desktop",
    set_mode_mobile: state => state.mode = "mobile",
    set_page_main: state => state.page = "main",
    set_page_second: state => state.page = "second",
    set_list_filter: (state, list) => state.list_filter = list,
    set_current_rating: state => state.current = "rating",
    set_current_fully_loaded_time: state => state.current = "fully_loaded_time",
    set_current_page_bytes: state => state.current = "page_bytes",
    set_current_google_pagespeed: state => state.current = "google_pagespeed",
    set_current_page_elements: state => state.current = "page_elements",
    set_sort: (state, value) => state.sort = value,
    set_order: (state, value) => state.order = value,
    set_cookie_day_scans: (state, {count=0, app}) => {
        state.cookie.day_scans = count;
        if (app) app.$cookies.set("day-scans", count, {maxAge: 24 * 3600})
    },
    set_cookie_already_subscribed: (state, {status=false, app}) => {
        state.cookie.already_subscribed = +status;
        if (app) app.$cookies.set("already-subscribed", +status, {maxAge: 365 * 24 * 3600})
    },
    set_cookie_confirmed: (state, {confirmed=false, app}) => {
        state.cookie.confirmed = +confirmed;
        if (app) app.$cookies.set("confirmed", +confirmed, {maxAge: 365 * 24 * 3600})
    },

    set_scanner_id: (state, id) => state.scanner.id = id,
    set_scanner_result: (state, result) => state.scanner.result = result,
    set_scanner_status: (state, status) => state.scanner.status = status,
    set_scanner_queue: (state, queue) => state.scanner.queue = queue,
    set_scanner_site: (state, site) => state.scanner.site = site,
    set_scanner_server: (state, server) => state.scanner.server = server,
    set_scanner_email: (state, email) => state.scanner.email = email,
    set_ratings_list: (state, list) => {
        state.ratings_list = list.filter(i => i.announcement && i.announcement.title)
    },
    set_youtube_list: (state, list) => state.youtube_list = list,
    set_statistics: (state, list) => state.statistics = list
};
