// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/PlayBold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/fonts/PlayBold.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/fonts/Play.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/fonts/Play.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face{font-family:\"Play\";font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:700;font-style:normal}@font-face{font-family:\"Play\";font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:400;font-style:normal}*{box-sizing:border-box;margin:0;padding:0}body{font-weight:400;line-height:1.2;color:#335766}body,button,input,select,textarea{font-family:\"Play\",sans-serif;font-size:19px}strong{font-weight:700}.block{padding:30px 10px;text-align:center}.block .title{font-size:25px;margin-bottom:10px}.block p{margin-bottom:20px}@media screen and (min-width:1020px){.wrapper{margin:auto;max-width:1170px}.wrapper.wide{max-width:1650px}.block{padding:80px 20px}.block .title{font-size:40px}.block p{max-width:770px;margin:0 auto 50px}}", ""]);
// Exports
module.exports = exports;
