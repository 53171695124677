// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/play.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".youtube-block{background:#e7e7e7}.youtube-block .items,.youtube-block p{margin-bottom:20px}.youtube-block .items .item{margin-bottom:10px;border-radius:3px;display:block;overflow:hidden;border:1px solid #ccc;position:relative}.youtube-block .items .item .play{position:absolute;left:0;top:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/50px;width:100%;height:100%;z-index:3}.youtube-block .items .item img{display:block;width:100%;height:auto;z-index:1;position:relative}.youtube-block .items .item iframe{position:absolute;top:0;left:0;width:100%;height:100%;z-index:2}@media screen and (min-width:720px){.youtube-block .items{display:flex;justify-content:space-between;flex-wrap:wrap}.youtube-block .items .item{margin-bottom:30px;width:calc(25% - 5px)}.youtube-block .btn{margin:auto;width:290px}}@media screen and (min-width:1020px){.youtube-block p{margin-bottom:50px}.youtube-block .items{align-items:flex-end;margin-bottom:0}.youtube-block .items .item{width:calc(25% - 20px);cursor:pointer;transition:.2s;box-shadow:0 0 0 rgba(0,0,0,.5)}.youtube-block .items .item:hover{transform:scale(1.1);box-shadow:0 0 20px rgba(0,0,0,.5)}}", ""]);
// Exports
module.exports = exports;
