<template lang="pug">
    main
        h1 {{ text.title }}
        div {{ text.description }}
        img(:src="image" alt="")
        SubscribePopup(title="Enter your email and get more checks" button_label="Send")
</template>

<script>
    import SubscribePopup from "~/components/SubscribePopup";
    import axios from "axios"
    import config from "~/config"

    export default {
        name: "share",
        components: {
            SubscribePopup
        },
        async fetch({store, query}) {
            if (query.scan) {
                const {data} = await axios.post(`${config.API}/api`, {id: query.scan});
                if (data.result) {
                    if (data.url) store.commit("set_scanner_site", data.url.replace(/^https?:\/\//, ""));
                    store.commit("set_scanner_result", data.result);
                }
            }
        },
        httpHeaders: () => ({
            'Last-Modified': new Date().toGMTString()
        }),
        computed: {
            text() {
                return this.languages.ru.share
            },
            image() {
                return this.$store.state.scanner.result && this.$store.state.scanner.result.screenshot ? this.$store.state.scanner.result.screenshot : null
            },
            locale() {
                return this.$route.query.lang && this.$route.query.lang !== "null" ? this.$route.query.lang : "ru"
            },
            language() {
                return this.languages[this.locale]
            }
        },
        head() {
            return {
                title: this.language.meta.title,
                meta: [
                    {hid: "twitter:card", name: "twitter:card", content: "summary_large_image"},
                    {hid: "twitter:title", name: "twitter:title", content: this.language.meta.title},
                    {hid: "twitter:description", name: "twitter:description", content: this.language.meta.description},
                    {hid: "twitter:image:src", name: "twitter:image:src", content: this.image},

                    {hid: "og:locale", property: "og:locale", content: this.locale},
                    {hid: "og:title", property: "og:title", content: this.language.meta.title},
                    {hid: "og:description", property: "og:description", content: this.language.meta.description},
                    {hid: "og:image", property: "og:image", content: this.image}
                ]
            }
        },
        mounted() {
            try {
                document.location.href = `${document.location.origin}?scan=${this.$route.query.scan}`;
            } catch (e) {}
        }
    }
</script>

<style lang="stylus">

</style>
