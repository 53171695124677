<template lang="pug">
    .tool-tip
        slot(name="label") ?
        .popup-tooltip
            .container
                slot
</template>

<script>
    export default {
        name: "ToolTip"
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .tool-tip
        display none

    @media screen and (min-width: desktop-width)
        .tool-tip
            display block
            text-align center
            color #01A0E2
            border 1px solid #01A0E2
            border-radius 50%
            width 16px
            height 16px
            line-height 14px
            font-size 12px
            margin-left 10px
            position relative
            z-index 10
            .popup-tooltip
                display none
                opacity 0
                z-index 10
            &:hover
                .popup-tooltip
                    display block
                    animation fadeIn .2s
                    position absolute
                    margin-left -130px
                    width 260px
                    top 18px
                    opacity 1
                    &::before
                    &::after
                        content ""
                        width 10px
                        height 10px
                        position absolute
                        background #f0f5f7
                        top 0
                        left 50%
                        margin-left 2px
                        transform rotate(-45deg)
                    &::before
                        z-index 1
                        box-shadow 0 0 5px rgba(0, 0, 0, .3)
                    &::after
                        z-index 3
                    .container
                        background #f0f5f7
                        position absolute
                        top 5px
                        left 0
                        width 100%
                        color dark
                        padding 5px 10px
                        box-shadow 0 0 5px rgba(0, 0, 0, .3)
                        border-radius 3px
                        z-index 2
                        font-size 14px

        .current
            .tool-tip
                color #fff
                border-color #fff

    @keyframes fadeIn
        0%
            display block
            opacity 0
        100%
            display block
            opacity 1
</style>
