<template lang="pug">
    .block.ratings-list-block#ratings(v-if="$store.state.ratings_list.length")
        .wrapper
            h3.title {{ title }}
            p(v-if="content") {{ content }}
            .list
                n-link.item(v-for="item in $store.state.ratings_list" :key="item.id" :to="`/${item.meta.slug}`")
                    span.image
                        LazyImg(v-if="item.announcement.image" :src="static_path(item.announcement.image, [500, 500])" :srcset="`${static_path(item.announcement.image, [1000, 1000])} 2x`" :alt="item.announcement.title" width="475" height="250")
                    span.info
                        span.title {{ item.announcement.title }}
                        span(v-if="item.announcement.description" v-html="item.announcement.description")
</template>

<script>
    import LazyImg from "~/components/LazyImg"

    export default {
        name: "TheRatingsList",
        props: {
            exclude: String,
            title: {
              type: String,
              default: "Our research on site loading speed in Russia"
            },
            content: {
              type: String,
              default: "Ratings of site loading speed by topic, updated every 24 hours"
            }
        },
        components: {
            LazyImg
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .ratings-list-block
        background #E7E7E7
        .list
            padding 10px 0
            .item
                padding 10px 0
                text-decoration none
                color inherit
                display block
                margin-bottom 30px
            .info
                text-align left
                max-width 480px
                margin auto
            .image
                display block
                margin-bottom 20px
            img
                max-width 100%
                height auto
                display block
                box-shadow 0 20px 50px rgba(0, 36, 51, .3)
                width 95%
                margin auto
                border-radius 3px
                object-fit contain
            .title
                text-decoration none
                margin-bottom 10px
                display block
            p
                margin-bottom 10px

    @media screen and (min-width: desktop-width)
        .ratings-list-block
            .list
                .item
                    display flex
                    margin-bottom 30px
                    &:nth-child(even)
                        flex-direction row-reverse
                    .image
                        width 50%
                        padding 0 30px
                        display flex
                        align-items center
                        justify-content center
                        margin 0
                    .info
                        width 50%
                        display flex
                        flex-direction column
                        justify-content center
                        .title
                            font-size 26px
                            margin-bottom 25px
                        p
                            margin 0 0 20px
                            text-align left
                            max-width none
</style>
