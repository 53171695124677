<template lang="pug">
    main.vkapp
        TheCheck
        ScanScreen
</template>

<style lang="stylus">
    main.vkapp
        header.check
            min-height 100vh
</style>

<script>
    import TheCheck from "~/components/TheCheck";
    import ScanScreen from "~/components/ScanScreen";

    export default {
        name: "vkapp",
        components: {
            ScanScreen,
            TheCheck,
        },
        httpHeaders: () => ({
            'Last-Modified': new Date().toGMTString()
        }),
        head() {
            return {
                title: 'Application to check the speed of the site online. Site download speed.',
                meta: [
                    {hid: "description", name: "description", content: 'Check the loading speed of the site and pages. What affects the speed of the site. How to analyze page speed tests? Get a free consultation!'},
                    {hid: "og:locale", property: "og:locale", content: "ru"},
                    {hid: "og:title", property: "og:title", content: 'Check the loading speed of the site online. The speed of loading sites.'},
                    {hid: "og:description", property: "og:description", content: 'Check the loading speed of the site and pages. What affects the speed of the site. How to analyze page speed tests? Get a free consultation!'},
                    {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
                    {hid: "twitter:image", property: "twitter:image", content: "https://loading.express/og-ru.jpg"},
                    {hid: "twitter:title", property: "twitter:title", content: "Check the loading speed of the site online. Site load speed."},
                    {hid: "twitter:description", property: "twitter:description", content: "Check the loading speed of the site and pages. What affects the speed of the site. How to analyze page speed tests? Get a free consultation!"},
                ],
                link: [
                    {rel: "alternate", hreflang: "en", href: "https://load.express/"}
                ]
            }
        }
    }
</script>
