<template lang="pug">
  main
    header.page
      h1 We use cookies!
      p.
        Loading.express, like most sites on the internet, uses cookies: this allows us to
        provide our users with our services, but also to analyze the data and to understand
        what we're doing wrong and where to develop our information resources and services.
    TheStatText(:html="text")
    TheFooter
</template>

<script>
import TheFooter from '~/components/TheFooter'
import TheStatText from '~/components/TheStatText'

export default {
    name: 'cookies',
    components: {
        TheStatText,
        TheFooter
    },
    httpHeaders: () => ({
        'Last-Modified': new Date().toGMTString()
    }),
    data() {
      return {
        text: `
          <h2>What is a cookie?</h2>
          <p>A cookie is a piece of data from a site that you have accessed and stored on your computer.</p>
          <h2>What juices do we use and for what purpose?</h2>
          <p>We use several different types of cookies:</p>
          <ul>
            <li>Cookies for user authentication in chats: we remember users, and this gives you
            don't have to write all your information and questions all over again.</li>
            <li>Cookies for statistics: We use several systems to collect statistics - it's important for us to know,
            how our users interact with our site. From this data we understand the size
            of our audience and what we're doing right and what we're doing wrong. We collect
            statistics through partners - Google Analytics, Yandex Metrika, Hotjar.</li>
            <li>Advertising: we use advertising display systems (Google Adwords, Yandex.Direct, as well as pixels
            retargeting social networks Vkontakte and Facebook) in order to show you contextual
            ads that match your interests.</li>
          </ul>
          <h2>What if you don't want to use cookies?</h2>
          <p>You can disable them in your browser's security settings. It is important to understand that the settings
          need to be applied in all browsers you use (on your computer and on your phone).
           In case you decide to disable cookies, keep in mind that some of the loading.express features
          will no longer be available to you or may work unpredictably.</p>
        `
      }
    }
}
</script>

<style lang="stylus">
  @require "~assets/style/VAR.styl"

  header.page
    color #fff
    height 100vh
    display flex
    flex-direction column
    background night-sky
    padding 20px
    align-items center
    justify-content center
    h1
      font-weight normal
      font-size 30px
      margin-bottom 30px
      text-align center
      line-height 30px
    p
      margin-bottom 30px

  .cookies-text
    padding 30px 10px
</style>
