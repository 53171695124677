<template lang="pug">
    .error-page
        h1 Упс...
        p Страница не найдена
        p
            a(href="/") Вернуться на главную
        img.cloud.back(src="~/assets/images/cloud-back.svg")
        img.cloud.middle(src="~/assets/images/cloud-middle.svg")
        img.cloud.front(src="~/assets/images/cloud-front.svg")
</template>

<script>
    export default {
        name: "error"
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .error-page
        background-color night-sky
        background-image url("~assets/images/stars.svg")
        min-height 100vh
        display flex
        align-items center
        justify-content center
        flex-direction column
        color #fff
        h1
            font-size 60px
            font-weight bold
        .cloud
            position absolute
            &.back
            &.middle
                display none
            &.front
                bottom 0
                left 0
                width 100%
        a
            color #fff
    @media screen and (min-width: desktop-width)
        .error-page
            padding-bottom 14vw
            h1
                font-size 120px
                position relative
                z-index 5
            p
                font-size 30px
                position relative
                z-index 5
            .cloud
                &.front
                    z-index 4
                &.middle
                    display block
                    bottom 0
                    left 20%
                    width 80%
                    z-index 3
                &.back
                    display block
                    bottom 0
                    left 25%
                    width 69%
                    z-index 2
</style>
