<template lang="pug">
    .stat-data
        .wrapper
            .title(v-if="title") {{ title }}
            .list-block
                .filter
                    .filter-block.wide
                        .label Parameter
                        .button(@click="$store.commit('set_current_rating')" :class="{current: $store.state.current === 'rating'}") {{ rating_button_label }}
                            ToolTip(v-if="$store.state.page_data.rating.rating_description_short") {{ $store.state.page_data.rating.rating_description_short }}
                        .button(@click="$store.commit('set_current_fully_loaded_time')" :class="{current: $store.state.current === 'fully_loaded_time'}") Loading speed
                            ToolTip The time when the page will be fully loaded on the device
                        .button(@click="$store.commit('set_current_page_bytes')" :class="{current: $store.state.current === 'page_bytes'}") Page weight
                            ToolTip Volume of all connecting resources on a page
                        .button(@click="$store.commit('set_current_page_elements')" :class="{current: $store.state.current === 'page_elements'}") Number of requests
                            ToolTip Number of requests to the server, which is necessary to paint the page
                        .button(@click="$store.commit('set_current_google_pagespeed')" :class="{current: $store.state.current === 'google_pagespeed'}") PageSpeed Insights
                            ToolTip Google's estimation of loading speed of sites

                    .filter-block.more-filters
                        .button(:class="{current: more_filters}" @click="more_filters = !more_filters")
                            img.icon(src="~/assets/images/controls-blue.svg" alt="Settings")
                            img.icon.current(src="~/assets/images/controls-white.svg" alt="Settings")
                            .name More options

                    // .filter-block.mobile-hidden(:class="{disabled: $store.state.current === 'rating', show: more_filters}")
                        .label Device
                        .button(@click="setMode('mobile')" :class="{current: $store.state.mode === 'mobile'}")
                          img.icon(src="~/assets/images/mobile-icon-blue.svg")
                          img.icon.current(src="~/assets/images/mobile-icon-white.svg")
                          .name Smartphone
                          ToolTip Test result in the mobile browser
                        .button(@click="setMode('desktop')" :class="{current: $store.state.mode === 'desktop'}")
                            img.icon(src="~/assets/images/desktop-icon-blue.svg")
                            img.icon.current(src="~/assets/images/desktop-icon-white.svg")
                            .name PC
                            ToolTip Test result in the browser on a normal computer

                    .filter-block.mobile-hidden(v-if="!without_pages" :class="{disabled: $store.state.current === 'rating', show: more_filters}")
                        .label Page
                        .button(@click="setPage('main')" :class="{current: $store.state.page === 'main'}")
                            slot(name="main_page_button")
                        .button(@click="setPage('second')" :class="{current: $store.state.page === 'second'}")
                            slot(name="second_page_button")

                    .filter-block.mobile-hidden(:class="{show: more_filters}")
                        .label {{ category_title }}
                            span.clear(v-if="list_filter.length" @click="list_filter = []") Show all
                        .select(@click.stop)
                            .select-label(:title="select_label" @click="open_select = !open_select")
                                span {{ select_label }}
                                ToolTip Select one or more sites for easy comparison.
                            .select-list(v-if="open_select")
                                .item(v-for="item in alphabet_list" :key="item" :class="{selected: list_filter.includes(item)}" @click="selectItem(item)") {{ item }}

                .info-block(v-if="info_text") {{ info_text }}

                .list#stat-list-items
                    .header
                        .head №
                        .head(@click="changeSort('title')") Title
                            .icon(v-if="this.$store.state.sort === 'title'" :class="{asc: this.$store.state.order > 0}")
                        .head(@click="changeSort('value')") {{ currentValueTitle }}
                            .icon(v-if="this.$store.state.sort === 'value'" :class="{asc: this.$store.state.order > 0}")

                    .item(v-for="(item, index) in list" :id="`item-${item._id}`")
                        .info
                            .index
                                img(src="~/assets/images/medal-1.svg" v-if="item.index === 1" alt="1")
                                img(src="~/assets/images/medal-2.svg" v-else-if="item.index === 2" alt="2")
                                img(src="~/assets/images/medal-3.svg" v-else-if="item.index === 3" alt="3")
                                span(v-else) {{ item.index < 10 ? 0 : "" }}{{ item.index }}
                            .logo(:style="{backgroundImage: item.info.logo ? `url(${static_path(item.info.logo)})` : null}")
                            .title
                                ToolTip.name(:style="{zIndex: 10 + list.length - index}") Go to the site "{{ item.info.title }}"
                                    a(:href="item.url" target="_blank" slot="label" rel="nofollow") {{ item.info.title }}
                                ToolTip.no-ssl(v-if="!item.ssl") No SSL certificate

                                a(v-if="item.id" :href="`https://loading.express/?test_id=${item.id}&server=msk`" target="_blank" rel="nofollow")
                                    ToolTip.timestamp.blue(v-if="item.timestamp") Updated: {{ item.timestamp.getDate() }}.{{ item.timestamp.getMonth() < 9 ? 0 : "" }}{{ item.timestamp.getMonth() + 1 }}.{{ item.timestamp.getFullYear() }}
                                span(v-else)
                                    ToolTip.timestamp(v-if="item.timestamp") Updated: {{ item.timestamp.getDate() }}.{{ item.timestamp.getMonth() < 9 ? 0 : "" }}{{ item.timestamp.getMonth() + 1 }}.{{ item.timestamp.getFullYear() }}
                                ToolTip.sandclock(v-if="(new Date() - item.timestamp)/1000/3600 > 48") Outdated data
                        .data
                            .line
                                .value {{ item.value }}
                                .rate(:style="{width: `calc((100% - 120px) * ${item.size} + 120px)`}")
                                    .green(:style="{opacity: item.rate > .5 ? (item.rate - .5) * 2 : 0}")
                                    .yellow(:style="{opacity: item.rate < .5 ? item.rate * 2 : 1}")
            slot
</template>

<script>
    import ToolTip from "~/components/ToolTip";

    export default {
        name: "TheStat",
        components: {
            ToolTip
        },
        props: {
            title: String,
            category_title: String,
            category_label: String,
            without_pages: Boolean
        },
        data() {
            try {
                document.addEventListener("click", () => this.open_select = false)
            } catch (e) {}
            return {
                open_select: false,
                more_filters: false
            }
        },
        computed: {
            rating_button_label() {
                return this.$store.state.page_data.rating.rating_button ? this.$store.state.page_data.rating.rating_button : "Overall rating"
            },
            select_label() {
                if (!this.$store.state.list_filter.length) return "All";
                return `${this.category_label ? this.category_label : "Selected"}: ${this.$store.state.list_filter.length}`
            },
            list_filter: {
                get() {
                    return [].concat(this.$store.state.list_filter)
                },
                set(value) {
                    this.$store.commit("set_list_filter", value)
                }
            },
            has_second_page() {
                return !!this.$store.state.data.filter(i => i.data && i.data.second).length
            },
            currentValueTitle() {
                switch (this.$store.state.current) {
                    case "rating":
                        return this.rating_button_label;
                    case "fully_loaded_time":
                        return "Loading speed in seconds";
                    case "page_bytes":
                        return "Page Weight";
                    case "google_pagespeed":
                        return "Google PageSpeed Insights";
                    case "page_elements":
                        return "Number of requests";
                }
            },
            alphabet_list() {
                let list = this.$store.state.data.map(item => item.info.title);
                list = list.sort();
                return list
            },
            list() {
                let list = this.$store.state.data.map(item => Object.assign({}, item));
                console.log(list);
                if (this.$store.state.page === "main") {
                    list = list.map(item => Object.assign(item, {
                        data: item.data.main,
                        timestamp: new Date(item.data.timestamp)
                    }))
                } else {
                    list = list.map(item => Object.assign(item, {
                        data: item.data.second,
                        url: item.second_url,
                        timestamp: new Date(item.data.timestamp)
                    }))
                }

                list = list.filter(i => i.data);

                list = list.map(item => {
                    item.id = item.data[this.$store.state.mode].id;
                    item.data = this.$store.state.mode === "desktop" ? item.data.desktop : item.data.mobile;
                    return item
                });
                list = list.sort((a, b) => a.data.rating > b.data.rating ? -1 : (a.data.rating < b.data.rating ? 1 : 0));
                list = list.map(item => Object.assign(item, {
                    url: `http${item.data.ssl ? "s" : ""}://${item.url}`,
                    ssl: item.data.ssl
                }));
                list = list.map(item => Object.assign(item, {data: +item.data[this.$store.state.current]}));
                list = list.sort((a, b) => {
                    let sort = a.data < b.data ? 1 : (a.data > b.data ? -1 : 0);
                    switch (this.$store.state.current) {
                        case "html_load_time":
                        case "fully_loaded_time":
                        case "page_bytes":
                        case "page_elements":
                            sort *= -1
                    }
                    return sort
                });

                list = list.map((item, index) => Object.assign(item, {index: index + 1}));

                if (this.$store.state.list_filter.length) {
                    list = list.filter(item => this.$store.state.list_filter.includes(item.info.title))
                }

                list = list.sort((a, b) => {
                    let sort = 0;
                    switch (this.$store.state.sort) {
                        case "value":
                            sort = a.data > b.data ? 1 : (a.data < b.data ? -1 : 0);
                            break;
                        case "title":
                            sort = a.info.title > b.info.title ? 1 : (a.info.title < b.info.title ? -1 : 0)
                    }
                    switch (this.$store.state.current) {
                        case "html_load_time":
                        case "fully_loaded_time":
                        case "page_bytes":
                        case "page_elements":
                            sort *= -1
                    }
                    return sort * this.$store.state.order
                });

                switch (this.$store.state.current) {
                    case "html_load_time":
                    case "fully_loaded_time":
                        list = list.map(item => Object.assign(item, {value: `${+(item.data / 1000).toFixed(3)} сек.`}));
                        break;
                    case "rating":
                    case "page_elements":
                        list = list.map(item => Object.assign(item, {value: +item.data}));
                        break;
                    case "google_pagespeed":
                        list = list.map(item => Object.assign(item, {value: Math.round(item.data * 100)}));
                        break;
                    case "page_bytes":
                        list = list.map(item => {
                            let size = +item.data;
                            let size_unit = "B";
                            if (size > 1024) {
                                size = (size / 1024).toFixed(2);
                                size_unit = "KB";
                            }
                            if (+size > 1024) {
                                size = (size / 1024).toFixed(2);
                                size_unit = "MB";
                            }
                            return Object.assign(item, {value: `${+size} ${size_unit}`})
                        });
                        break;
                }

                const values = list.map(item => item.data);
                const min = Math.min(...values);
                const max = Math.max(...values);

                list = list.map(item => {
                    let size = +item.data;
                    let rate = size;
                    switch (this.$store.state.current) {
                        case "rating":
                            size = (size - min) / (max - min);
                            rate = size;
                            break;
                        case "html_load_time":
                        case "fully_loaded_time":
                        case "page_bytes":
                        case "page_elements":
                            size = (size - min) / (max - min);
                            rate = 1 - size;
                    }
                    return Object.assign(item, {rate, size})
                });
                return list
            },
            info_text() {
                const data = {
                    rating: this.$store.state.page_data.rating.rating_description,
                    fully_loaded_time: "Loading speed - the time when the page will be fully loaded on the device. The shorter this time is - the better. The perfect time is less than 3 seconds.",
                    page_bytes: "Page Weight - volume of all connecting resources on a page. The smaller the volume, the faster the loading is when the Internet is slow. In addition, the larger the volume more money will be charged from you in roaming. The perfect weight is up to 1MB.",
                    page_elements: "Number of requests - number of requests to the server, which is necessary to paint the page. The fewer requests, the lower is the waiting time. Perfect - up to 100 requests.",
                    google_pagespeed: "Pagespeed - Google's estimation of loading speed of sites on a 100-point scale. As you get closer to 100, the better is. Ideal - 100 points."
                };
                return data[this.$store.state.current] || false
            }
        },
        methods: {
            setMode(mode="desktop") {
                /** @namespace this.$store.commit **/
                if (this.$store.state.current !== "rating") this.$store.commit(`set_mode_${mode}`)
            },
            setPage(page="main") {
                if (this.$store.state.current !== "rating") this.$store.commit(`set_page_${page}`)
            },
            selectItem(value) {
                const list = [].concat(this.list_filter);
                const index = list.indexOf(value);
                if (index < 0) {
                    list.push(value)
                } else {
                    list.splice(index, 1);
                }
                this.list_filter = list
            },
            changeSort(value) {
                this.$store.commit("set_sort", value);
                if (value === this.$store.state.sort) {
                    this.$store.commit("set_order", this.$store.state.order > 0 ? -1 : 1)
                } else {
                    this.$store.commit("set_order", -1)
                }
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .stat-data
        background #E7E7E7
        padding 10px 0
        .title
            font-size 25px
            padding 10px
            text-align center
        .list-block
            background #fff

        .filter
            padding 10px
            position relative
            z-index 5
            .filter-block
                margin-bottom 10px
                display flex
                flex-direction column
                &.disabled
                    opacity .5
                &.mobile-hidden
                    display none
                &.show
                    display flex
                .label
                    font-size 12px
                    color rgba(51, 87, 102, 0.7)
                    text-align left
                    line-height 15px
                    margin-bottom 5px
                    .clear
                        margin-left 10px
                        color #d52055
                .button
                    color #01A0E2
                    border 2px solid #01A0E2
                    height 40px
                    display flex
                    line-height 16px
                    align-items center
                    justify-content center
                    font-size 15px
                    border-top-left-radius 5px
                    border-top-right-radius 5px
                    border-bottom-width 1px
                    .icon
                        height auto
                        width auto
                        max-height 22px
                        max-width 24px
                        display block
                        margin-right 10px
                        &.current
                            display none
                    &.current
                        background #01A0E2
                        color #fff
                        .icon
                            display none
                            &.current
                                display block
                    & + .button
                        border-radius 0
                        border-top-width 1px
                        border-bottom-width 1px
                    &:last-child
                        border-top-width 1px
                        border-bottom-width 2px
                        border-top-left-radius 0
                        border-top-right-radius 0
                        border-bottom-left-radius 5px
                        border-bottom-right-radius 5px
                        &:first-child
                            border-top-left-radius 5px
                            border-top-right-radius 5px
                            border-top-width 2px
                .select
                    position relative
                    line-height 16px
                    font-size 15px
                    .select-label
                        border 2px solid #01A0E2
                        border-radius 5px
                        padding 0 15px
                        height 40px
                        display flex
                        align-items center
                        position relative
                        span
                            display block
                            max-width 100%
                            overflow hidden
                            text-overflow ellipsis
                        &::before
                            content ""
                            position absolute
                            top 50%
                            right 10px
                            width 6px
                            height 6px
                            margin-top -4px
                            border-right 2px solid
                            border-bottom 2px solid
                            transform rotate(45deg)

                    .select-list
                        position absolute
                        top 45px
                        left 0
                        width 100%
                        max-height 300px
                        overflow auto
                        background #fff
                        box-shadow 0 0 10px rgba(0, 0, 0, .2)
                        border-radius 5px
                        padding 10px 0
                        z-index 10
                        .item
                            padding 0 15px 0 50px
                            position relative
                            line-height 22px
                            margin-bottom 10px
                            &::before
                                content ""
                                width 18px
                                height 18px
                                border 1px solid #D9DEE1
                                position absolute
                                left 15px
                                top 1px
                            &:last-child
                                margin-bottom 0
                            &.selected
                                &::before
                                    background blue
                                    border-color blue
                                &::after
                                    content ""
                                    position absolute
                                    top 2px
                                    left 21px
                                    border-right 2px solid #fff
                                    border-bottom 2px solid #fff
                                    width 6px
                                    height 12px
                                    transform rotate(45deg)

        .info-block
            background #F0F5F7
            padding 15px 15px 15px 40px
            font-size 15px
            position relative
            margin 0 10px 20px
            &::before
                content ""
                width 20px
                height 20px
                background url("~assets/images/info.svg") no-repeat center
                background-size contain
                position absolute
                left 10px
                top 15px

        .list
            border-top 1px solid #e7e7e7
            padding 10px 10px 30px
            .header
                display flex
                flex-wrap wrap
                font-size 12px
                line-height 20px
                .head
                    display flex
                    align-items center
                    width 45px
                    margin-right 10px
                    justify-content center
                    .icon
                        width 5px
                        height 7px
                        background url("~assets/images/arrow.svg") no-repeat center
                        background-size contain
                        margin-left 3px
                        &.asc
                            transform rotate(180deg)
                    & + .head
                        width calc(100% - 55px)
                        margin 0
                        & + .head
                            width 100%
            .item
                border-bottom 1px solid #DDE7F1
                font-size 15px
                margin-bottom 10px
                .info
                    display flex
                    align-items center
                    padding 2px 0
                    .index
                        width 45px
                        padding 0 5px
                        margin-right 10px
                        text-align center
                        height 25px
                        line-height 25px
                        img
                            display block
                            width 35px
                            height 25px
                    .logo
                        width 30px
                        height 20px
                        background-size contain
                        background-position center
                        background-repeat no-repeat
                        margin-right 10px
                    .title
                        width calc(100% - 95px)
                        font-size 0
                        padding 0 10px
                        text-align left
                        .name
                            max-width calc(100% - 30px)
                            display inline-block
                            font-size 15px
                            vertical-align middle
                            text-decoration none
                            color inherit
                            margin-left 0
                            border 0
                            border-radius 0
                            width auto
                            a
                                white-space nowrap
                                overflow hidden
                                color inherit
                                text-decoration none
                                text-overflow ellipsis
                                display block
                                max-width 100%
                            .popup-tooltip
                                display none
                            &:nth-last-child(3)
                                max-width calc(100% - 56px)
                        .no-ssl
                            margin-left 10px
                            width 16px
                            height 21px
                            background url("~assets/images/no-ssl.svg") no-repeat center
                            background-size contain
                            display inline-block
                            vertical-align middle
                            font-size 0
                            border 0
                            border-radius 0
                            z-index 1
                            .popup
                                top 26px
                            &:hover
                                z-index 5
                        .timestamp
                            display none
                .data
                    display flex
                    height 29px
                    align-items center
                    background linear-gradient(to right, #e7e7e7 0px, #e7e7e7 1px, transparent 1px, transparent 52px)
                    background-size 52px
                    border-right 1px solid #e7e7e7
                    border-top 1px solid #e7e7e7
                    .line
                        width 100%
                        position relative
                        .value
                            color #fff
                            padding-left 10px
                            height 20px
                            line-height 20px
                            position absolute
                            top 0
                            left 0
                            z-index 3
                        .rate
                            height 20px
                            position relative
                            background #D31E53
                            .yellow
                            .green
                                position absolute
                                top 0
                                left 0
                                width 100%
                                height 100%
                            .yellow
                                background #F1B41C
                                z-index 1
                            .green
                                background #52B94F
                                z-index 2


    @media screen and (min-width: tablet-width)
        .stat-data
            .filter
                .filter-block
                    flex-direction row
                    flex-wrap wrap
                    &.mobile-hidden
                        display flex
                    &.more-filters
                        display none
                    .label
                        width 100%
                    .button
                        width 50%
                        border-top-left-radius 5px
                        border-bottom-left-radius 5px
                        border-top-right-radius 0
                        border-bottom-right-radius 0
                        border-width 2px
                        border-right-width 1px
                        & + .button
                            border-radius 0
                            border-width 2px
                            border-left-width 1px
                            border-right-width 1px
                            &:last-child
                                border-width 2px
                                border-left-width 1px
                                border-top-left-radius 0
                                border-bottom-left-radius 0
                                border-top-right-radius 5px
                                border-bottom-right-radius 5px
                    .select
                        width 100%
                    &.wide
                        .button
                            width 20%
                            text-align center
            .list
                .header
                    padding 20px 0 10px
                    border-bottom 1px solid #dde7f1
                    .head
                        & + .head
                            width 295px
                            justify-content flex-start
                            padding-left 50px
                            & + .head
                                width calc(100% - 350px)
                                padding-left 0
                .item
                    display flex
                    margin 0
                    .info
                        width 350px
                    .data
                        width calc(100% - 350px)
                        height 42px
                        border-top 0


    @media screen and (min-width: desktop-width)
        .stat-data
            padding 20px 0
            .title
                font-size 40px
                margin-bottom 20px
            .filter
                display flex
                flex-wrap wrap
                justify-content space-between
                padding 25px 30px 15px
                .filter-block
                    width calc((100%  - 30px) / 2)
                    &.wide
                        width 100%
                    .button
                    .select
                        cursor pointer
                    &.disabled
                        .button
                        .select
                            cursor no-drop
                    .label
                        .clear
                            cursor pointer
            .info-block
                margin 0 30px 30px
                font-size 19px
                padding 20px 25px 20px 57px
                &::before
                    left 25px
                    top 20px
            .list
                padding 10px 30px 50px
                .header
                    .head
                        & + .head
                            width 385px
                            & + .head
                                width calc(100% - 440px)
                        &:not(first-child)
                            cursor pointer
                .item
                    .info
                        width 440px
                        .title
                            margin 0
                            .sandclock
                                margin-left 10px
                                width 18px
                                height 18px
                                background url("~assets/images/sand-clock.svg") no-repeat center
                                background-size contain
                                display inline-block
                                vertical-align middle
                                font-size 0
                                border 0
                                border-radius 0
                                z-index 1
                            .timestamp
                                margin-left 10px
                                width 18px
                                height 18px
                                background url("~assets/images/passage-of-time.svg") no-repeat center
                                background-size contain
                                display inline-block
                                vertical-align middle
                                font-size 0
                                border 0
                                border-radius 0
                                z-index 1
                                &.blue
                                    background url("~assets/images/passage-of-time-blue.svg") no-repeat center
                                .popup
                                    top 26px
                                &:hover
                                    z-index 5
                            .name
                                max-width calc(100% - 84px)
                                &:hover
                                    .popup-tooltip
                                        display block
                                        left 50%
                    .data
                        width calc(100% - 440px)
</style>
