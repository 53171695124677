<template lang="pug">
    footer.white
        .wrapper
            .text
                p LOADING.express © 2002-{{ new Date().getFullYear() }}
                p "LOADING.RU" LLC | INN: 9705211423 | OGRN: 1237700756017
                p
                    | If you have any questions, please contact us
                    a(href="tel:+74993027807") +7 (499) 302-78-07
                    | or help@loading.express
                p.docs
                    a(href="https://loading.express/privacy" target="_blank" rel="noreferrer") Privacy Policy
                    | |
                    a(href="https://status.loading.express/") Status of services
            .social
                a.vk(href="https://vk.com/loading.express" target="_blank" rel="noreferrer") LOADING.express VKontakte public forum
                a.fb(href="https://www.facebook.com/checkspeedsite/" target="_blank" rel="noreferrer") LOADING.express Facebook page
                a.tg(href="https://t.ax.digital/loadingexpress" target="_blank" rel="noreferrer") LOADING.express Telegram Channel
                a.yt(href="https://www.youtube.com/channel/UCbhSw8k_NEnHT6qAhaHY1aw?sub_confirmation=1" target="_blank" rel="noreferrer") LOADING.express YouTube channel
</template>

<script>
    export default {
        name: "TheFooter",
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    footer
        text-align center
        padding 20px 10px
        &.black
            background #00121A
            color #fff
        &.white
            background #fff
            color #00121A
        .logo
            width 120px
            margin-bottom 10px
        .text
            font-size 14px
        a
            color purple
            text-decoration none
            margin 0 5px
        .docs
            a
                &:first-child
                    margin-left 0
        .social
            margin-top 10px
            display flex
            align-items center
            justify-content center
            a
                margin 5px
                border-radius 3px
                width 40px
                height 40px
                display block
                background-repeat no-repeat
                background-position center
                font-size 0
                &.vk
                    background-image url("~assets/images/vk.svg")
                    background-size 20px
                    background-color #45668E
                &.fb
                    background-image url("~assets/images/fb.svg")
                    background-size 10px
                    background-color #3B5998
                &.tg
                    background-image url("~assets/images/tg.svg")
                    background-size 20px
                    background-color #0088CC
                &.yt
                    background-image url("~assets/images/youtube.svg")
                    background-size 20px
                    background-color #FF0000


    @media screen and (min-width: desktop-width)
        footer
            padding 20px
            .wrapper
                display flex
                align-items center
            .logo
                width 146px
                margin 0 20px 0 0
            .text
                width 100%
                line-height 20px
                text-align left
                a:hover
                    text-decoration underline
            .social
                justify-content flex-end
                margin-top 0
                a
                    &:hover
                        opacity .7
</style>
