<template lang="pug">
    .btn(@click="$emit('click')" :is="type" :to="to" :href="href" :target="target")
        slot
</template>

<script>
    export default {
        name: "FormButton",
        props: {
            type: {
                type: String,
                validate: value => ["button", "a", "div", "n-link"].includes(value),
                default: "button"
            },
            to: String,
            href: String,
            target: String
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .btn
        background purple
        min-height 50px
        color #fff
        border 0
        width 100%
        text-transform uppercase
        font-size 14px
        display inline-flex
        align-items center
        justify-content center
        text-decoration none
        padding 5px 20px
        outline none
        border-radius 3px
        &.pulse
            animation pulse 1s infinite

    @media screen and (min-width: desktop-width)
        .btn
            height 50px
            line-height 20px
            cursor pointer
            &.pulse:hover
                animation none
            &:hover
                background #B4204F
                box-shadow 0 5px 15px rgba(0, 36, 51, .3)

    @keyframes pulse
        0%
            box-shadow 0 0 0 0 rgba(213, 32, 85, .8)
        80%
            box-shadow 0 0 0 10px transparent
        100%
            box-shadow 0 0 0 0 transparent
</style>
