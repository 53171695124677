<template lang="pug">
    .subscribe-form-block
        .wrapper
            .subscribe-text-block(v-show="confirmed")
                .ttl Thanks for subscribing!
                LazyImg(:src="require('~/assets/images/subscribe.gif')" alt="Thanks for subscribing")
                .txt You will learn about accelerating sites and high conversions.
                .txt Every Tuesday and Friday we are live on &nbsp;
                    a(href="http://youtube.com/user/alekseyleonidovich" target="_blank" rel="nofollow") YouTube
                .banner
                    a.btn(href="https://скорость-сайта.рф/auto-check-speed/" target="_blank") Auto-checks
                    a.btn(href="https://l.iw.media/fastvps" target="_blank") Fast hosting
                    a.btn(href="https://l.iw.media/w-tools" target="_blank") Secure CDN
                    a.btn(href="https://скорость-сайта.рф/instruction-speedup/" target="_blank") Detailed audit

            .subscribe-text-block(v-show="!confirmed && subscribed") A link has been sent to confirm
            form.form(v-show="!confirmed && !subscribed" @submit.prevent="submit" data-goal="forma_top" :class="{loading}")
                input.form-item(v-model="email" placeholder="me@email.com" autocapitalize="off" autocorrect="off" required autofocus)
                button.btn Subscribe
</template>

<script>
    import LazyImg from "~/components/LazyImg";
    import FormButton from "~/components/FormButton";
    export default {
        name: "SubscribeForm",
        components: {FormButton, LazyImg},
        props: {
            addressbook: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                email: "",
                wait_confirm: false,
                loading: false
            }
        },
        methods: {
            submit() {
                if (this.email) {
                    this.loading = true;
                    this.$sendGoal("subscribe");
                    /** @namespace this.$store.dispatch **/
                    this.$store.dispatch("SEND_FORM", {action: "/subscribe", data: {email: this.email, addressbook: this.addressbook}}).then(() => {
                        this.email = "";
                        this.loading = false;
                        this.$store.commit("set_cookie_already_subscribed", {status: true, app: this});
                    }).catch(console.error)
                }
            }
        },
        computed: {
            subscribed() {
                return this.$store.state.cookie.already_subscribed
            },
            confirmed() {
                if (this.$route.query.confirm === "yes"  || this.$store.state.cookie.confirmed) {
                    this.$store.commit("set_cookie_confirmed", {confirmed: true, app: this});
                    this.$store.commit("set_cookie_already_subscribed", {status: true, app: this});
                    return true
                }
                return false
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .subscribe-form-block
        width 100%

        .subscribe-text-block
            padding 20px
            width 100%
            text-align center
            max-width 1150px
            margin auto
            font-size 22px
            .txt
                margin-bottom 5px
            img
                max-width 100%
                height auto
                margin-bottom 30px
            .ttl
                font-size 36px
                margin-bottom 30px
            a
                color blue
                &.btn
                    color #fff
            .banner
                margin-top 25px
                display flex
                flex-wrap wrap
                a
                    margin 5px


        form.form
            margin auto
            &.loading
                position relative
                &::before
                    content ""
                    background night-sky
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    opacity .7
                    z-index 1
                &::after
                    content ""
                    background url("~assets/images/spinner.svg") no-repeat center
                    background-size contain
                    width 30px
                    height 30px
                    top 50%
                    left 50%
                    margin -15px 0 0 -15px
                    position absolute
                    z-index 2
                    animation 1s rotate infinite
    @media screen and (min-width: tablet-width)
        .subscribe-form-block
            .subscribe-text-block
                .banner
                    flex-wrap nowrap

    @keyframes rotate
        from
            transform rotate(0deg)
        to
            transform rotate(360deg)
</style>
