<template lang="pug">
.block.about#about
  .wrapper
    h2.title We guarantee to speed up your site in 3 days
    p Contract, the guarantee and humane approach are attached. Who we are?
    .ceo
      LazyImg(:src="require('~/assets/images/photo/alexey.jpg')" alt="Alexey Makhmetkhazhiyev - Marketer since LOADING.express" width="350" height="350")
      .info
        .name Alexey Makhmetkhazhiyevв
        .desc Marketer since 2007
        .social
          a.vk(href="https://vk.com/t1184858" target="_blank" rel="noreferrer") Alexey's personal VKontakte page
          a.tw(href="https://twitter.com/vigodnee" target="_blank" rel="noreferrer") Alexey's personal Twitter
          a.fb(href="https://www.facebook.com/naarende" target="_blank" rel="noreferrer") Alexey's personal Facebook page
          a.tg(href="https://t.me/axdigital" target="_blank" rel="noreferrer") Alexey's personal Telegram
        .text-description
          p Alexey started as a SEO-specialist. He succeeded in mining traffic from search engines and went further, developing in analytics. Cause when you get a ton of visitors to your site, you need to convert them into buyers!
          p Usability and conversions are what Alexey specializes in today. Generating and testing of hypotheses based on behavioral analytics is a complex and unique process for each type of business. The experience gained by Alexey over 13 years of practice is now worth tens of thousands of dollars. This kind of specialists are called Growth Hackers abroad, which means "Explosive marketing.
          p Over the course of his career, Alexey has helped more than 1100 projects in 8 countries. And this is just the beginning for him.
    .ceo
      LazyImg(:src="require('~/assets/images/photo/robot.jpg')" alt="There could be a picture of you here" width="350" height="350")
      .info
        .name Send your CV
        .desc We raise the new stars every year
        .text-description
          p Real star warriors are working in our team. Most were trained from the scratch. Some of them have gone to conquer other galaxies. And we are ready to teach the best of the best.
          p Write or call us if you want to join our team.
        .btn(@click="resume_popup=!resume_popup") Submit CV
  ResumePopup(v-if="resume_popup" @close="resume_popup = false")
</template>

<script>
import ResumePopup from "~/components/ResumePopup";
import LazyImg from "~/components/LazyImg";

export default {
  name: "about",
  components: {
    ResumePopup,
    LazyImg
  },
  data() {
    return {showAllFAQ: false, resume_popup: false};
  }
}
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"
    .about
        background #fff
        padding-bottom 50px
        .text-description
            margin-top 30px
        .social
            display flex
            margin-top 5px
            a
                margin 5px
                border-radius 3px
                width 30px
                height 30px
                display block
                background-repeat no-repeat
                background-position center
                font-size 0
                &.vk
                    background-image url("~assets/images/vk.svg")
                    background-size 16px
                    background-color #45668E
                &.fb
                    background-image url("~assets/images/fb.svg")
                    background-size 8px
                    background-color #3B5998
                &.tg
                    background-image url("~assets/images/tg.svg")
                    background-size 16px
                    background-color #0088CC
                &.yt
                    background-image url("~assets/images/youtube.svg")
                    background-size 16px
                    background-color #FF0000
                &.tw
                    background-image url("~assets/images/tw.svg")
                    background-size 16px
                    background-color #00ACEE
                &:first-child
                    margin-left 0
        .ceo
            display flex
            flex-wrap wrap
            align-items flex-start
            justify-content center
            img
                width 300px
                height auto
                max-width 100%
                border-radius 3px
        .info
            text-align left
            .name
                font-size 26px
            .desc
                font-size 16px
                color #666
            p
                margin-bottom 20px
        .team
            display flex
            flex-wrap wrap
            .item

                img
                    width 300px
                    height 300px
                    border-radius 3px
    @media screen and (min-width: tablet-width)
        .about
            .ceo
                flex-wrap nowrap
                margin-bottom 50px
                .info
                    margin-left 40px
            .team
                justify-content center
                .item
                    width 300px
                    margin 15px
                    &:last-child
                        display none
    @media screen and (min-width: desktop-width)
        .about
            .social
                a
                    &:hover
                        opacity .7
            .ceo
                justify-content flex-start
                img
                    width 350px
                    height 350px
                    margin 10px
            .team
                justify-content space-between
                flex-wrap nowrap
                .item
                    width 350px
                    min-width 300px
                    max-width 350px
                    margin 0 10px
                    &:last-child
                        display block
                    img
                        width 350px
                        height auto
                        max-width 100%

</style>
