<template lang="pug">
  .scroll-arrow
</template>

<script>
export default {
    name: 'ScrollArrow'
}
</script>

<style lang="stylus">
  @require "~assets/style/VAR.styl"

  .scroll-arrow
    position absolute
    bottom 20px
    left 50%
    background url("~assets/images/mouse-scroll.svg") no-repeat center
    background-size contain
    width 28px
    height 40px
    margin-left -20px
</style>
