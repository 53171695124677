<template lang="pug">
    form(@submit.prevent="submit($event.currentTarget)")
        slot
        ThePopup.error(v-if="error_url" @close="error_url = !error_url")
            .title The site does not respond
            p
                | Perhaps the site is unavailable or you entered the address incorrectly.
                br
                | Check if the address is correct and try again.
            .btn.grey(@click="error_url = !error_url") Close
</template>

<script>
    import axios from "axios"
    import config from "~/config";
    import ThePopup from "~/components/ThePopup";
    const API_URL = `${config.API}/api`;
    const MAIL_URL = `${config.API}/mail`;

    export default {
        name: "FormWrapper",
        props: {
            action: String,
        },
        components: {
            ThePopup
        },
        methods: {
            submit(form) {
                const site_input = form.querySelector("input[name=\"site\"]");
                const email_input = form.querySelector("input[name=\"email\"]");
                const server_input = form.querySelector(".ct[name=\"server\"]");

                if(site_input && site_input.value) {
                    // axios.post(API_URL, {ping: site_input.value}).then(({data}) => {
                    //     if(data.ping === true){
                            this.$store.commit("set_scanner_site", site_input ? site_input.value : null);
                            this.$store.commit("set_scanner_server", server_input ? server_input.value : null);
                            this.$store.commit("set_scanner_email", email_input ? email_input.value : null);
                            if(this.$store.state.scanner.server === 'main' || this.$store.state.scanner.server.length < 1){
                                this.$cookies.set("server", 'main', {maxAge: 7 * 24 * 3600});
                            }
                            site_input.value = "";

                            this.$store.commit("set_scanner_status", "start");
                            if (this.$store.state.scanner.email) {
                                this.$sendGoal("subscribe");
                                this.$store.dispatch("SEND_FORM", {action: "/subscribe", data: {email: this.$store.state.scanner.email, addressbook: "1922766"}}).then(() => {
                                    this.$store.commit("set_cookie_already_subscribed", {status: true, app: this});
                                }).catch(console.error);
                            } else {
                                this.$store.commit("set_cookie_day_scans", {count: this.$store.state.cookie.day_scans + 1, app: this});
                            }
                        // } else {
                        //     axios.post(MAIL_URL, {
                        //         site: site_input.value,
                        //         mail_subject: 'Failed to start scanning ' + site_input.value+ ' #ERROR',
                        //         comment: 'Failed to start scanning ' + site_input.value
                        //     });
                        //     this.error_url = true;
                        //     return false
                        // }
                    // });
                }
            },
        },
        data() {
            return {
                error_url: false
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"
    .error
        .title
            font-size 28px
        p
            text-align left
            font-size 18px
            font-weight normal
        .btn.grey
            background #999
            margin-bottom 15px
    .form-item
        width 100%
        display block
        border 1px solid #D9DEE1
        box-sizing border-box
        height 40px
        padding 10px 20px
        line-height 20px
        margin-bottom 20px
        outline none
        font-size 15px
        &:focus
            border-color blue

    @media screen and (min-width: desktop-width)
        .form-item
            height 50px
            line-height 30px
            font-size 19px
            &::-webkit-input-placeholder
                font-size 15px
        input.form-item:-moz-placeholder
            font-size 15px
</style>
