<template lang="pug">
    main
        header.sub
            SubscribeForm(addressbook="1922766")
        TheFooter
</template>

<script>
    import TheFooter from "~/components/TheFooter"
    import SubscribeForm from "~/components/SubscribeForm";

    export default {
        components: {
            SubscribeForm,
            TheFooter
        },
        httpHeaders: () => ({
            'Last-Modified': new Date().toGMTString()
        }),
        head() {
            return {
                title: "Sign up for a webinar on website acceleration",
                meta: [
                    {hid: "description", name: "description", content: "Find out live why your site is slow and what the dangers of slow sites are"},
                    {hid: "og:locale", property: "og:locale", content: "ru"},
                    {hid: "og:title", property: "og:title", content: "Sign up for a webinar on website acceleration"},
                    {hid: "og:description", property: "og:description", content: "Find out live why your site is slow and what the dangers of slow sites are"},
                    {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
                ]
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    header.sub
        color #fff
        min-height 100vh
        display flex
        flex-direction column
        background night-sky
        padding 20px
        align-items center
        justify-content center
        position relative
        h1
            font-weight normal
            font-size 30px
            margin-bottom 30px
            text-align center
            line-height 30px
        p
            margin-bottom 30px
        .form
            display flex
            width 100%
            flex-direction column
            max-width 820px
            input
                width 100%
                display block
                border 1px solid #d9dee1
                padding 10px 20px
                line-height 20px
                outline 0
                font-size 15px
                -webkit-appearance none
                border-radius 3px 3px 0 0
                height 50px!important
                margin 0!important
                &:focus
                    border-color #d9dee1!important
            button
                background purple
                height 50px!important
                color #fff
                border 0
                width 100%
                text-transform uppercase
                font-size 14px
                padding 0 40px
                margin 0
                border-radius 0 0 3px 3px
            a
                background purple
                height 50px!important
                color #fff
                border 0
                width 320px
                max-width 100%
                text-transform uppercase
                font-size 20px
                padding 0 40px
                margin auto
                border-radius 3px
                text-decoration none
                box-shadow 0 20px 50px rgba(0, 180, 255, .3)
                display flex
                justify-content center
                align-items center

    @media screen and (min-width: tablet-width)
        header.sub
            h1
                font-size 40px
                margin-bottom 40px
                line-height 40px
            form.form
                box-shadow 0 20px 50px rgba(0, 180, 255, .3)
            .form
                flex-direction row
                input
                    border-radius 3px 0 0 3px
                button
                    width auto!important
                    border-radius 0 3px 3px 0
                    cursor pointer
                    &:hover
                        background rgba(213, 32, 85, .8)
                    &:active
                        transform: scale(.98)
                a
                    width auto!important
                    border-radius 3px
                    cursor pointer
                    &:hover
                        background rgba(213, 32, 85, .8)
                    &:active
                        transform: scale(.98)
</style>
