<template lang="pug">
    .stat-text-block
        .wrapper(v-html="html")
</template>

<script>
    export default {
        name: "TheStatText",
        props: {
            html: String
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .stat-text-block
        padding 20px 10px
        p
        ul
        ol
        img
        h2
        h3
            margin-bottom 10px
            &:last-child
                margin-bottom 0
        img
            display block
            max-width 100%
            height auto
        ul
            list-style disc
            padding-left 30px
        a
            color #01a0e2
        h2
        h3
          font-weight bold
        h2
          font-size 1.4em
        h3
          font-size 1.2em

    @media screen and (min-width: desktop-width)
        .stat-text-block
            padding 100px 0
            .wrapper
                max-width 800px
            p
            ul
            ol
            img
            h2
            h3
                margin-bottom 50px
</style>
