<template lang="pug">
    ThePopup.order-popup(@close="$emit('close')")
        .success(v-if="success")
            .title Thank you for taking an interest in our company
            .image
                img.rocket(src="/images/rocket.svg")
                img.cloud.back(src="~assets/images/cloud-back.svg")
                img.cloud.middle(src="~assets/images/cloud-middle.svg")
                img.cloud.front(src="~assets/images/cloud-front.svg")
        form.form(v-else @submit.prevent="submit" :class="{loading}")
            .title Send CV
            input.form-item(v-model="form.phone" placeholder="Phone number" autocapitalize="off" autocorrect="off" required autofocus)
            input.form-item(v-model="form.name" placeholder="Your name" autocapitalize="off" autocorrect="off")
            textarea.form-item(v-model="form.message" rows="3" placeholder="Links to your projects, resumes and description of your skills and abilities" autocapitalize="off" autocorrect="off")
            button.btn Submit
            fz
</template>

<script>
    import ThePopup from "~/components/ThePopup";
    import Fz from "~/components/fz";
    import axios from "axios"
    import config from "~/config"

    const MAIL_URL = `${config.API}/mail`;

    export default {
        name: "ResumePopup",
        components: {
            Fz,
            ThePopup
        },
        data() {
            return {
                form: {
                    phone: "",
                    name: "",
                    message: ""
                },
                loading: false,
                success: false
            }
        },
        methods: {
            submit() {
                this.success = true;
                this.$sendGoal("lead-resume");
                return axios.post(MAIL_URL, Object.assign(this.form, {
                    mail_subject: "CV",
                    client_id: this.$cookies.get("utmstat_client_id"),
                    current_url: document.location.href
                })).then(() => {
                    this.success = true
                });
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .order-popup
        .form
            &.loading
                position relative
                &::before
                    content ""
                    background #fff
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    opacity .7
                    z-index 1
                &::after
                    content ""
                    background url("~assets/images/spinner-dark.svg") no-repeat center
                    background-size contain
                    width 30px
                    height 30px
                    top 50%
                    left 50%
                    margin -15px 0 0 -15px
                    position absolute
                    z-index 2
                    animation 1s rotate infinite
            .form-item
                width 100%
                max-width 400px
                display block
                border 1px solid #D9DEE1
                box-sizing border-box
                //border-radius 10px
                height 40px
                padding 10px 20px
                line-height 20px
                margin 0 auto 20px
                outline none
                font-size 18px
                &:focus
                    border-color blue
            textarea.form-item
                height auto
            .btn
                width 180px

        .success
            .image
                width 260px
                height 260px
                overflow hidden
                border-radius 50%
                margin 10px auto 0
                background night-sky url("~assets/images/sky-bottom.svg")
                background-size 600px
                background-position center
                position relative
                .rocket
                    position absolute
                    left 50%
                    top 30px
                    width 110px
                    margin-left -55px
                    z-index 3
                .cloud
                    position absolute
                    &.front
                        bottom 0
                        left 0
                        width 550px
                        z-index 4
                    &.middle
                        bottom 0
                        left 10px
                        width 480px
                        z-index 2
                    &.back
                        bottom 0
                        left -20px
                        width 400px
                        z-index 1

    @keyframes rotate
        from
            transform rotate(0deg)
        to
            transform rotate(360deg)

    @media screen and (min-width: desktop-width)
        .order-popup
            .success
                .image
                    width 450px
                    height 450px
                    background-size 800px
                    margin-top 20px
                    .rocket
                        width 200px
                        top 55px
                        margin-left -100px
                    .cloud
                        &.front
                            width 800px
                            left 20px
                        &.middle
                            width 700px
                            left 50px
                            bottom 20px
                        &.back
                            width 550px
                            bottom 20px
</style>
