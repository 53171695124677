// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/images/stars.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".error-page{background-color:#1b2638;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");min-height:100vh;display:flex;align-items:center;justify-content:center;flex-direction:column;color:#fff}.error-page h1{font-size:60px;font-weight:700}.error-page .cloud{position:absolute}.error-page .cloud.back,.error-page .cloud.middle{display:none}.error-page .cloud.front{bottom:0;left:0;width:100%}.error-page a{color:#fff}@media screen and (min-width:1020px){.error-page{padding-bottom:14vw}.error-page h1{font-size:120px;position:relative;z-index:5}.error-page p{font-size:30px;position:relative;z-index:5}.error-page .cloud.front{z-index:4}.error-page .cloud.middle{display:block;bottom:0;left:20%;width:80%;z-index:3}.error-page .cloud.back{display:block;bottom:0;left:25%;width:69%;z-index:2}}", ""]);
// Exports
module.exports = exports;
