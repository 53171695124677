<template lang="pug">
    main
        header.blog-index
            .blog-wrap
                h1 {{ title }}
                .content(v-html="content")
        TheFooter
</template>

<script>
    import TheFooter from '~/components/TheFooter'
    import axios from "axios"

    export default {
        components: {
            TheFooter
        },
        httpHeaders: () => ({
            'Last-Modified': new Date().toGMTString()
        }),
        async asyncData({ params }) {
            const { data } = await axios.get(`https://app.loading.express/blog?slug=${params.slug}`);
            return {title: data.items[0].title,content: data.items[0].content};
        },
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"
    .blog-wrap
        max-width 700px
        margin auto
</style>
