<template lang="pug">
    .chrome-popup(v-if="show")
        .close(@click="close")
        .title Check site speed in one click!
        .info
            .lg
            p Install the browser extension and check the speed of the site in one click
        .meta
            .rating
                span Rating:
                span.stars
            .setup
                span Installations:
                span.str 748
            .install
                a.btn(href="https://chrome.google.com/webstore/detail/loadingexpress/pbjkhpopkeemapfmncbmpleaecapdbfg" rel="nofollow" target="_blank") Install
</template>

<script>
    import FormButton from "~/components/FormButton";

    export default {
        name: "chromeEx",
        components: {
            FormButton
        },
        data() {
            return {
                show: false,
                details: false,
                TimeOut: null
            }
        },
        methods: {
            close() {
                this.show = false;
                this.$cookies.set("chrome-ext", 1, {maxAge: 365 * 24 * 3600})
            }
        },
        mounted() {
            if (process.browser && !navigator.userAgent.includes("Chrome-Lighthouse") && !this.$cookies.get("chrome-ext") && window.chrome && window.chrome.runtime && window.chrome.runtime.sendMessage) {
                this.TimeOut = setTimeout(() => {
                    this.show = true
                }, 10*1000);
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .chrome-popup
        display none

    @media screen and (min-width: tablet-width)
        .chrome-popup
            position fixed
            bottom 20px
            left 20px
            background #fff
            box-shadow 0 20px 50px rgba(0, 36, 51, .3)
            padding 20px
            display flex
            justify-content space-between
            z-index 100
            border-radius 3px
            flex-wrap wrap
            align-items center
            width 500px
            .title
                width 100%
                margin-bottom 5px
                font-weight bold
                font-size 22px
            p
                font-size 16px
                margin-top 10px
            .close
                position absolute
                right 0
                top 0
                cursor pointer
                width 40px
                height 40px
                &:after
                    content ""
                    width 25px
                    height 2px
                    position absolute
                    top 20px
                    right 10px
                    background #335766
                    margin 0 0 0 -6px
                    transform rotate(-45deg)
                &:before
                    content ""
                    width 25px
                    height 2px
                    position absolute
                    top 20px
                    right 10px
                    background #335766
                    margin 0 0 0 -6px
                    transform rotate(45deg)
            .info
                display flex
                align-items center
                margin-bottom 10px
                .lg
                    min-width 64px
                    min-height 64px
                    margin-right 10px
                    background url("~assets/images/chrome.svg") no-repeat center / 64px;
            .meta
                display flex
                width 100%
                justify-content space-between
                font-size 16px
                align-items center
                .setup
                    .str
                        font-weight bold
                        margin-left 5px
                .rating
                    display flex
                    align-items center
                    .stars
                        width 75px
                        height 15px
                        margin-left 5px
                        background url("~assets/images/star.svg") repeat-x center / 15px;
            .btn
                height 40px
                width auto
                background dark
                &.link
                    background none
                    color dark
</style>
