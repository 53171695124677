import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

import _36ded8b4 from '../pages/blog/index.vue'
import _591c56de from '../pages/cookies.vue'
import _4e93c424 from '../pages/share.vue'
import _9cf7e34c from '../pages/sub.html.vue'
import _6b4f7a4a from '../pages/vkapp.vue'
import _3a4c1b44 from '../pages/blog/_slug.vue'
import _06e04561 from '../pages/index.vue'
import _0529a419 from '../pages/_slug.vue'

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _36ded8b4,
    name: "blog"
  }, {
    path: "/cookies",
    component: _591c56de,
    name: "cookies"
  }, {
    path: "/share",
    component: _4e93c424,
    name: "share"
  }, {
    path: "/sub.html",
    component: _9cf7e34c,
    name: "sub.html"
  }, {
    path: "/vkapp",
    component: _6b4f7a4a,
    name: "vkapp"
  }, {
    path: "/blog/:slug",
    component: _3a4c1b44,
    name: "blog-slug"
  }, {
    path: "/",
    component: _06e04561,
    name: "index"
  }, {
    path: "/:slug",
    component: _0529a419,
    name: "slug"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decodeURIComponent(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
