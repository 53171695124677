<template lang="pug">
    .items.blog-list
        n-link.item(v-for="item in posts" :key="item.slug" :to="`/blog/${item.slug}`")
            LazyImg(v-if="item.thumb_small" :src="item.thumb_small" :srcset="item.thumb_big" :alt="item.title")
            .ttl {{ item.title }}
</template>

<script>
    import LazyImg from "~/components/LazyImg";

    export default {
        name: "BlogPostList",
        props: {
            src: {
                type: Number,
                default: 10
            }
        },
        components: {
            LazyImg,
        },
        mounted() {
            for (let item of this.$store.state.blog_list.items) {
                this.posts.push({
                    title: item.title,
                    thumb_small: item.thumb_small,
                    thumb_big: item.thumb_big,
                    slug: item.slug
                });
            }
        },
        data() {
            return {
                posts: []
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"
    .blog-list
        margin-bottom 20px
        .item
            margin-bottom 10px
            border-radius 3px
            display flex
            flex-wrap wrap
            overflow hidden
            align-items center
            justify-content center
            padding 0
            background #fff
            border 1px solid #ccc
            position relative
            color #000
            text-decoration none
            .ttl
                padding 20px
                display block
            img
                display block
                width 100%
                height auto
                z-index 1
                position relative
    @media screen and (min-width: tablet-width)
        .blog-list
            display flex
            flex-wrap wrap
            justify-content space-between
            align-items stretch
            .item
                margin-bottom 20px
                width calc((100% - 20px) / 2)

    @media screen and (min-width: desktop-width)
        .blog-list
            align-items stretch
            .item
                width calc(25% - 20px)
                cursor pointer
                transition .2s
                box-shadow 0 0 0 rgba(0, 0, 0, .5)
                &:hover
                    transform scale(1.1)
                    box-shadow 0 0 20px rgba(0, 0, 0, .5)
                img
                    max-height 150px
                    object-fit cover
</style>
