<template lang="pug">
    .top-block
        .wrapper
            .logo(@click="goTo('check');showBat();") LOADING.express
            nav
                a.click(@click="goTo('reviews')") Reviews
                a.click(@click="goTo('about')") About Us
                a.click(@click="goTo('live')") Broadcasts
                a.click(@click="goTo('order')") Speed up the site
            a.phone(href="tel:+74993027807") +7 (499) 302-78-07
            .callback-link(data-callback="now" @click="clb") Callback?
            a.lang(href="https://loading.express" rel="noreferrer") Russian version
        callback#topcall(v-if="callb" @close="callb = false")
</template>

<script>
    import callback from "~/components/callback";
    import elementOffset from "~/plugins/elementOffset";
    export default {
        name: "topPanel",
        components: {
            callback
        },
        data() {
            return {
                callb: false,
                bat: false,
                cnt: 0
            }
        },
        methods: {
            goTo(id) {
                window.scrollTo(0, elementOffset(document.getElementById(id)).top - 40)
            },
            clb() {
                this.callb = true;
                this.$sendGoal("click-callback");
                us_client.fetchAjaxForm();
            },
            showBat() {
                this.cnt++;
                if(this.cnt > 2) {
                    this.cnt = 0;
                    this.bat = true;
                    document.querySelector(".witch").play();
                    setTimeout(() => {
                        this.bat = false;
                    }, 1500)
                }
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"
    .top-block
        position fixed
        top 0
        left 0
        width 100%
        height 50px
        background rgba(27, 38, 56, 1)
        z-index 10
        .wrapper
            display flex
            justify-content space-between
            align-items center
            height 50px
            padding 0 20px
            .logo
                display block
                background url("~assets/images/loading-logo.svg") no-repeat left center / 180px
                width 100%
                height 30px
                font-size 0
            nav
                display none
            a.phone
                color #fff
                text-decoration none
                font-size 0
                min-width 40px
                min-height 50px
                display: flex;
                align-items: center;
                background url("~assets/images/phone.svg") no-repeat 5px center / 30px
            a.lang
                min-width 40px
                min-height 50px
                background url("~assets/images/ru.svg") no-repeat center / 30px
                font-size 0
                margin-left 10px
            .callback-link
                display none
        .title
            font-size 25px
            margin-bottom 10px

    @media screen and (min-width: desktop-width)
        .top-block
            .wrapper
                .logo
                    min-width 180px
                    max-width 180px
                    cursor pointer
                nav
                    display flex
                    width 100%
                    justify-content space-between
                    margin 0 15px
                    align-items stretch
                    a
                        color #fff
                        font-size 14px
                        text-decoration none
                        cursor pointer
                        border-bottom 2px solid transparent
                        padding 16px 15px
                        border-radius 3px
                        display flex
                        justify-content center
                        align-items center
                        text-align center
                        &:hover
                            border-bottom 2px solid #d52055
                            background rgba(255,255,255,0.1)
                .callback-link
                    cursor pointer
                    font-size 14px
                    border-bottom solid 1px #fff
                    margin-left 10px
                    display block
                    color #fff
                a.phone
                    font-size 20px
                    background none
                    width 200px
                    min-width 180px
                    padding-left 0
            .title
                font-size 40px
</style>
