<template lang="pug">
    header.stat-page
        picture
            source(srcset="~/assets/images/stat-image-1.webp 1x, ~/assets/images/stat-image-1-2x.webp 2x" type="image/webp")
            img(src="~/assets/images/stat-image-1.jpg" srcset="~/assets/images/stat-image-1-2x.jpg 2x" :alt="title")
        .shadow
            .wrapper
                //nuxt-link.logo(to="/")
                //    img(src="~/assets/images/logo.svg" alt="The company's project Innovation-WEB")
                h1.title(v-if="title") {{ title }}
                slot
        ScrollArrow
</template>

<script>
    import ScrollArrow from '~/components/ScrollArrow'

    export default {
        name: "TheHeaderStat",
      components: {
          ScrollArrow
      },
      props: {
            title: String
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    header.stat-page
        background-repeat no-repeat
        background-position center
        background-size cover
        background-color #000
        color #fff
        text-align center
        padding 0
        display block
        min-height auto
        position relative
        picture
            position absolute
            left 0
            top 0
            width 100%
            height 100%
            z-index 1
            img
                width 100%
                height 100%
                object-fit cover
                object-position center
        .shadow
            background rgba(0, 0, 0, .5)
            padding 30px
            width 100%
            /*min-height 100vh*/
            display flex
            align-items center
            position relative
            z-index 2
        .logo
            max-width 194px
            display block
            margin 0 auto 20px
            height auto
            img
                display block
        .title
            font-size 25px
            font-weight bold
            margin-bottom 20px

    @media screen and (min-width: tablet-width)
        header.stat-page
            .logo
                margin-bottom 30px
            .title
                font-size 40px
                margin-bottom 30px

    @media screen and (min-width: desktop-width)
        header.stat-page
            .logo
                margin-bottom 50px
            .title
                font-size 60px
                max-width 800px
                margin 0 auto 50px
</style>
