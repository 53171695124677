<template lang="pug">
    label.fz-block
        input.fz(type="checkbox" required name="fz" checked)
        small.label
            a(href="https://innovation-web.ru/agreement.html" target="_blank" rel="noreferrer") I agree
            | &nbsp;с&nbsp;
            a(href="https://innovation-web.ru/privacy.html" target="_blank" rel="noreferrer") the terms of personal data processing
</template>

<script>
    export default {
        name: "fz"
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"
    .order-form-block
        label.fz-block
            a
                color #fff
    label.fz-block
        font-size 16px
        margin-top 20px
        display flex
        line-height 16px
        align-items center
        justify-content center
        input[type="checkbox"]
            -webkit-appearance none
            min-width 15px
            max-width 15px
            width 15px
            min-height 15px
            max-height 15px
            height 15px
            border 1px solid blue
            background #fff
            border-radius 3px
            display flex
            align-items: center;
            justify-content: center;
            &:checked:before
                content '✓'
                font-size 12px
                color blue


        a
            color blue
        .label
            margin-left 10px
</style>
