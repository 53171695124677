<template lang="pug">
    .block.faq#faq(itemscope itemtype="https://schema.org/FAQPage")
        .wrapper
            h2.title Frequently asked questions about site acceleration
            p The most important questions we receive every day
            .quests
                .quest(itemscope itemprop="mainEntity" itemtype="https://schema.org/Question")
                    .question(itemprop="name") How does your service differ from Google PageSpeedand other similar services?
                    .answer(itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer")
                        p(itemprop="text") СServers of our service are located in Russia, unlike Google, whose servers are located in Europe, and all the speed checking tests  are coming from Switzerland or other EU countries. Our loading speed tests are performed from Moscow, St. Petersburg, Novosibirsk and other cities of Russia.
                .quest(itemscope itemprop="mainEntity" itemtype="https://schema.org/Question")
                    .question(itemprop="name") Can we automatically check the speed of the site with your service from Russia?
                    .answer(itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer")
                        p(itemprop="text")
                            | Yes, you can. We have site speed monitoring. Now we can monitor sites from several cities in Russia, Ukraine, Belarus, Kazakhstan and a few European countries. Our tariffs are flexible and our prices start from 950 rubles per month. Write to sale@loading.express,
                            a(@click="callb = true") request a call back
                            | or leave your number in
                            a(@click="goTo('order')") the form
                            | at the bottom of the site.
                .quest(itemscope itemprop="mainEntity" itemtype="https://schema.org/Question")
                    .question(itemprop="name") How many seconds should the site load?
                    .answer(itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer")
                        p(itemprop="text") Normal site loading speed is up to 3 seconds. However, this indicator  is divided into the response from the server (up to 0.5 seconds) and  the rendering of the first content (up to 1  second).
                .quest(itemscope itemprop="mainEntity" itemtype="https://schema.org/Question")
                    .question(itemprop="name")  I have my own programmer, how could you help me?
                    .answer(itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer")
                        p(itemprop="text") For you we have a service of technical implementation of our recommendations. Here's what it will look like:
                        ol
                            li We will conduct a detailed analysis of your site;
                            li We will write a report that will provide a step-by-step guide to acceleration;
                            li We will consult your programmers and check their work. We will give you a list of clear actions to optimize site performance.
                        p If you do what's in our report, your site will load at all speed standards.

                .quest(itemscope itemprop="mainEntity" itemtype="https://schema.org/Question")
                    .question(itemprop="name") What does your data mean? How can I interpret it?
                    .answer(itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer")
                        p(itemprop="text") There is a question mark next to each parameterWhen you click on it, you will get a detailed description of each parameter. In case of difficulty or questions, you can contact us – we will tell you all about it and provide solutions to improve your performance.
                .quest(itemscope itemprop="mainEntity" itemtype="https://schema.org/Question")
                    .question(itemprop="name") Do you speed up the mobile version of the site too?
                    .answer(itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer")
                        p(itemprop="text") Of course. Mobile traffic is already exceeding desktop traffic. We pay attention to the mobile loading speed  primarily. You may notice that our service checks the loading speed of the mobile version of the site.
                .quest(itemscope itemprop="mainEntity" itemtype="https://schema.org/Question")
                    .question(itemprop="name") What is the result of acceleration you guarantee?
                    .answer(itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer")
                        p(itemprop="text") It is quite difficult to predict the results of website acceleration, because the results depend on the source data. In each individual case we will give our special guarantees and recommendations for you and your programmers.
                .quest(itemscope itemprop="mainEntity" itemtype="https://schema.org/Question")
                    .question(itemprop="name") Do you work under a contract? Do you accept non-cash payments?
                    .answer(itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer")
                        p(itemprop="text") We work under a contract only and accept non-cash payments. You will get the signed original documents after the contract is fulfilled. We will also expect our signed copies from you. Before we get to work, you will need to scan the necessary documents and send them to us.
                .quest(itemscope itemprop="mainEntity" itemtype="https://schema.org/Question")
                    .question(itemprop="name") What if my programmers break your acceleration?
                    .answer(itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer")
                        p(itemprop="text") Yes, we are often asked this question. And such apprehension is quite reasonable. That's why we guarantee the result of the site acceleration up to 45 days. During this period, we will help you maintain the loading speed of your site under all circumstances.
                .quest(itemscope itemprop="mainEntity" itemtype="https://schema.org/Question")
                    .question(itemprop="name") Will I be able to update the site/template/topic after acceleration?
                    .answer(itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer")
                        p(itemprop="text") You will be able to update both the version of your  content management system and plugins., you will be able to update the plugins. In each case, there are different nuances that we will clarify with you before we start the work.
        callback(v-if="callb" @close="callb = false")
</template>

<script>
    import callback from "~/components/callback";
    import elementOffset from "~/plugins/elementOffset";
    export default {
        name: "FAQ",
        components: {
            callback
        },
        data() {
            return {
                callb: false
            }
        },
        methods: {
            goTo(id) {
                window.scrollTo(0, elementOffset(document.getElementById(id)).top - 40)
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"
    .faq
        background: #222;
        color: #fff;
        .quests
            text-align left
            display flex
            flex-wrap wrap
            .btn
                margin 10px auto
                border-radius 3px
                max-width 300px
            .quest
                width 100%
                padding 20px
                background #333
                margin 10px
                border-radius 3px
                .question
                    font-size 24px
                    margin-bottom 20px
                    display flex
                    &::before
                        content '?'
                        font-size 48px
                        font-weight bold
                        margin-right 20px
                        color blue
                .answer
                    p
                        font-size 18px
                        margin 0
                        max-width 100%
                        line-height 1.5em
                    a
                        color white
                        text-decoration underline
                        margin 0 5px
                        &.nrm
                            margin-right 0
                    ol
                        padding 10px 0 10px 30px
                    li
                        margin 5px 0
    @media screen and (min-width: tablet-width)
        .faq
            .quests
                .quest
                    width calc(50% - 20px)
                    a
                        cursor pointer
                        &:hover
                            text-decoration underline
</style>
