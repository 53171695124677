<template lang="pug">
    main
        TheHeaderStat(:title="page_data.header.title")
            p(v-if="page_data.header.text" v-html="page_data.header.text")
            a.btn(v-if="page_data.header.link_to_data" href="#stat" @click="go_to_anchor('stat')") {{ page_data.header.link_to_data }}
            a.how(v-if="page_data.header.link_to_text && page_data.text.after" href="#after_text" @click="go_to_anchor('after_text')") {{ page_data.header.link_to_text }}

        TheStatText(v-if="page_data.text.before" :html="page_data.text.before")

        TheStat#stat(:title="page_data.rating.title" ref="stat" :category_title="page_data.rating.category_title" :category_label="page_data.rating.category_label" :without_pages="!page_data.rating.second_page.title")
            img.icon(v-if="page_data.rating.main_page.icon" :src="static_path(page_data.rating.main_page.icon)" slot="main_page_button")
            img.icon.current(v-if="page_data.rating.main_page.checked_icon" :src="static_path(page_data.rating.main_page.checked_icon)" slot="main_page_button")
            .name(slot="main_page_button" v-if="page_data.rating.main_page.title") {{ page_data.rating.main_page.title }}
            ToolTip(slot="main_page_button") Home page test result

            img.icon(v-if="page_data.rating.second_page.icon" :src="static_path(page_data.rating.second_page.icon)" slot="second_page_button")
            img.icon.current(v-if="page_data.rating.second_page.checked_icon" :src="static_path(page_data.rating.second_page.checked_icon)" slot="second_page_button")
            .name(slot="second_page_button" v-if="page_data.rating.second_page.title") {{ page_data.rating.second_page.title }}
            ToolTip(slot="second_page_button") Test result of the page "{{ page_data.rating.second_page.title }}"

            .rating-buttons
                FormButton(type="n-link" to="/") Check site speed
                FormButton(@click="monitoring_popup = true") I want monitoring
                FormButton(@click="order_popup = true") Speed up my site
                // FormButton(type="a" href="https://widget.loading.express" target="_blank") Affiliate Program
                OrderPopup(v-if="order_popup" @close="order_popup = false")
                MonitoringPopup(v-if="monitoring_popup" @close="monitoring_popup = false")

        TheChampions

        TheStatText#after_text(v-if="page_data.text.after" ref="after_text" :html="page_data.text.after")

        OrderForm(goal="lead-researh")

        TheRatingsList(:exclude="page_data._id" title="Other ratings" content="")

        TheFooter
</template>

<script>
    import TheHeaderStat from "~/components/TheHeaderStat"
    import FormButton from "~/components/FormButton"
    import TheStatText from "~/components/TheStatText"
    import TheStat from "~/components/TheStat"
    import TheFooter from "~/components/TheFooter"
    import ToolTip from "~/components/ToolTip";
    import elementOffset from "~/plugins/elementOffset";
    import OrderForm from "~/components/OrderForm";
    import TheChampions from "~/components/TheChampions";
    import OrderPopup from "~/components/OrderPopup";
    import MonitoringPopup from "~/components/MonitoringPopup";
    import SubscribePopup from "~/components/SubscribePopup";
    import TheRatingsList from '~/components/TheRatingsList'

    export default {
        name: "rating_page",
        components: {
          TheRatingsList,
            SubscribePopup,
            OrderPopup,
            MonitoringPopup,
            TheChampions,
            OrderForm,
            ToolTip,
            TheHeaderStat,
            FormButton,
            TheStatText,
            TheStat,
            TheFooter
        },
        httpHeaders: () => ({
            'Last-Modified': new Date().toGMTString()
        }),
        async validate({store, route}) {
            try {
                await store.dispatch("GET_PAGE", route.params.slug);
                await store.dispatch("GET_STAT", store.state.page_data.rating.category);
                await store.dispatch("GET_RATINGS_LIST", store.state.page_data._id);
                return true
            } catch (e) {
                return false
            }
        },
        head() {
            return {
                title: this.page_data.meta.title,
                meta: [
                    {hid: "description", name: "description", content: this.page_data.meta.description},
                    {hid: "og:locale", property: "og:locale", content: "ru"},
                    {hid: "og:title", property: "og:title", content: this.page_data.meta.title},
                    {hid: "og:description", property: "og:description", content: this.page_data.meta.description},
                    {hid: "og:image", property: "og:image", content: this.static_path(this.page_data.meta.og_image)},
                ]
            }
        },
        computed: {
            page_data() {
                return this.$store.state.page_data
            }
        },
        methods: {
            go_to_anchor(anchor) {
                if (this.$refs[anchor]) {
                    const offset = elementOffset(this.$refs[anchor].$el);
                    window.scrollTo(0, offset.top)
                }
            }
        },
        data() {
            return {
                order_popup: false,
                monitoring_popup: false
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    header.stat-page
        .btn
            margin-top 20px
        a.how
            color #fff
            margin-top 15px
            display block
            font-size 16px

    .stat-data
        .rating-buttons
            padding 20px 10px
            .btn
                margin-bottom 10px

    @media screen and (min-width: tablet-width)
        header.stat-page
            .btn
                width auto
                margin-top 30px

    @media screen and (min-width: desktop-width)
        header.stat-page
            p
                max-width 800px
                margin auto
            .btn
                margin-top 50px
        .stat-data
            .rating-buttons
                display flex
                justify-content space-around
                .btn
                    width auto
</style>
