<template lang="pug">
    .block.youtube-block#live
        .wrapper
            h2.title Watch our live streams about websites loading speed
            p We conduct audits for your sites live, come and see our high competence.
            .items
                .item(v-for="item in video" :key="item.id" @click="item.show = true")
                    .play(v-if="!item.show")
                    LazyImg(:src="item.image" :alt="item.alt" :style="{opacity: +(!item.show)}" width="320" height="180")
                    iframe(:src="`https://www.youtube.com/embed/${item.id}?autoplay=1`" v-if="item.show" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
            FormButton(type="a" href="https://www.youtube.com/channel/UCbhSw8k_NEnHT6qAhaHY1aw?sub_confirmation=1" target="_blank" rel="noreferrer") SUBSCRIBE ON OUR YOUTUBE CHANNEL
</template>

<script>
    import FormButton from "~/components/FormButton";
    import LazyImg from "~/components/LazyImg";

    export default {
        name: "TheYouTube",
        components: {
            LazyImg,
            FormButton
        },
        mounted() {
            for (let item of this.$store.state.youtube_list.items) {
                this.video.push({
                    id: item.id,
                    image: item.image,
                    show: false,
                    alt: item.alt
                });
            }
        },
        data() {
            return {
                video: []
            }
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .youtube-block
        background #E7E7E7
        p
            margin-bottom 20px
        .items
            margin-bottom 20px
            .item
                margin-bottom 10px
                border-radius 3px
                display block
                overflow hidden
                border 1px solid #ccc
                position relative
                .play
                    position absolute
                    left 0
                    top 0
                    background url("~assets/images/play.svg") no-repeat center / 50px
                    width 100%
                    height 100%
                    z-index 3
                img
                    display block
                    width 100%
                    height auto
                    z-index 1
                    position relative
                iframe
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    z-index 2

    @media screen and (min-width: tablet-width)
        .youtube-block
            .items
                display flex
                justify-content space-between
                flex-wrap wrap
                .item
                    margin-bottom 30px
                    width calc((100% - 20px) / 4)
                    // box-shadow 0 0 20px rgba(0, 0, 0, .5)
            .btn
                margin auto
                width 290px

    @media screen and (min-width: desktop-width)
        .youtube-block
            p
                margin-bottom 50px
            .items
                align-items flex-end
                margin-bottom 0
                .item
                    width calc(25% - 20px)
                    cursor pointer
                    transition .2s
                    box-shadow 0 0 0 rgba(0, 0, 0, .5)
                    &:hover
                        transform scale(1.1)
                        box-shadow 0 0 20px rgba(0, 0, 0, .5)
</style>
