<template lang="pug">
    .popup.close-popup(@click="$emit('close')")
        .h-container
            .container(@click.stop)
                .close(@click="$emit('close')")
                .scroller
                    slot
</template>

<script>
    import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock"

    export default {
        name: "ThePopup",
        mounted() {
            disableBodyScroll(this.$el);
        },
        beforeDestroy() {
            enableBodyScroll(this.$el)
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .popup
        display flex
        position fixed
        top 0
        left 0
        right 0
        bottom 0
        width 100%
        height 100%
        background rgba(0, 0, 0, .8)
        align-items center
        z-index 1000
        color dark
        .h-container
            width 100%
            max-height calc(100vh - 70px)
            .scroller
                overflow auto
                max-height calc(100vh - 130px)
                padding 0 30px
            .container
                background #fff
                box-shadow 0 20px 50px rgba(0, 36, 51, .3)
                border-radius 3px
                margin 0 auto
                width 100%
                max-width 770px
                max-height calc(100vh - 70px)
                padding 30px 0
                text-align center
                font-size 20px
                font-weight bold
                position relative
            .close
                width 40px
                height 40px
                top 0
                right 0
                position absolute
                &::before
                &::after
                    content ""
                    width 25px
                    height 2px
                    position absolute
                    top 20px
                    right 10px
                    background #335766
                    margin 0 0 0 -6px
                &::before
                    transform rotate(45deg)
                &::after
                    transform rotate(-45deg)

    @media screen and (min-width: tablet-width)
        .popup
            .h-container
                max-height 100vh
                .scroller
                    max-height calc(100vh - 60px)
                .container
                    margin 0 auto
                    max-height 100vh

    @media screen and (min-width: desktop-width)
        .popup
            .h-container
                .close
                    cursor pointer
                .scroller
                    padding 0 50px
                .container
                    padding 50px 0
</style>
