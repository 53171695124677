<template lang="pug">
    ThePopup.subscribe-popup(@close="$emit('close')")
        .success(v-if="success")
            .title A link has been sent to confirm
            .image
                img.rocket(src="/images/rocket.svg")
                img.cloud.back(src="~assets/images/cloud-back.svg")
                img.cloud.middle(src="~assets/images/cloud-middle.svg")
                img.cloud.front(src="~assets/images/cloud-front.svg")
        form.form(v-else @submit.prevent="submit" :class="{loading}")
            .title {{ title }}
            input.form-item(v-model="email" placeholder="me@email.com" autocapitalize="off" autocorrect="off" required autofocus)
            FormButton {{ button_label }}
            fz
</template>

<script>
    import ThePopup from "~/components/ThePopup";
    import Fz from "~/components/fz";
    import FormButton from "~/components/FormButton";

    export default {
        name: "SubscribePopup",
        props: {
            title: {
                type: String,
                default: "Sign up for a live broadcast"
            },
            button_label: {
                type: String,
                default: "Sign up"
            },
            timeout: {
                type: Number,
                default: 1500
            },
            default_show: Boolean
        },
        components: {
            FormButton,
            Fz,
            ThePopup
        },
        data() {
            return {
                show: false,
                email: "",
                success: false,
                loading: false
            }
        },
        methods: {
            submit() {
                if (this.email) {
                    this.loading = true;
                    this.$sendGoal("subscribe");
                    /** @namespace this.$store.dispatch **/
                    this.$store.dispatch("SEND_FORM", {action: "/subscribe", data: {email: this.email, addressbook: "89052579", test_id: 'https://loading.express/?test_id=' + this.$store.state.scanner.id + '&server=' + this.$store.state.scanner.server, url: this.$store.state.scanner.site}}).then(() => {
                    }).catch(console.error)
                    this.$store.dispatch("SEND_FORM", {action: "/subscribe", data: {email: this.email, addressbook: "1922766"}}).then(() => {
                        this.email = "";
                        this.success = true;
                        this.loading = false;
                        this.$store.commit("set_cookie_already_subscribed", {status: true, app: this});
                    }).catch(console.error)
                }
            },
            close() {
                this.show = false
            }
        },
        mounted() {
        }
    }
</script>

<style lang="stylus">
    @require "~assets/style/VAR.styl"

    .subscribe-popup
        form.form
            .title
                margin-bottom 20px
            .form-item
                width 100%
                display block
                border 1px solid #D9DEE1
                box-sizing border-box
                height 40px
                padding 10px 20px
                line-height 20px
                margin-bottom 0
                outline none
                font-size 18px
                &:focus
                    border-color blue
            &.loading
                position relative
                &::before
                    content ""
                    background #fff
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    opacity .7
                    z-index 1
                &::after
                    content ""
                    background url("~assets/images/spinner-dark.svg") no-repeat center
                    background-size contain
                    width 30px
                    height 30px
                    top 50%
                    left 50%
                    margin -15px 0 0 -15px
                    position absolute
                    z-index 2
                    animation 1s rotate infinite
        .success
            .image
                width 260px
                height 260px
                overflow hidden
                border-radius 50%
                margin 10px auto 0
                background night-sky url("~assets/images/sky-bottom.svg")
                background-size 600px
                background-position center
                position relative
                .rocket
                    position absolute
                    left 50%
                    top 30px
                    width 110px
                    margin-left -55px
                    z-index 3
                .cloud
                    position absolute
                    &.front
                        bottom 0
                        left 0
                        width 550px
                        z-index 4
                    &.middle
                        bottom 0
                        left 10px
                        width 480px
                        z-index 2
                    &.back
                        bottom 0
                        left -20px
                        width 400px
                        z-index 1

    @media screen and (min-width: tablet-width)
        .subscribe-popup
            form.form
                display flex
                flex-wrap wrap
                justify-content center
                .title
                    width 100%
                .form-item
                    width calc(100% - 200px)
                .btn
                    width 200px

    @media screen and (min-width: desktop-width)
        .subscribe-popup
            form.form
                .title
                    margin-bottom 40px
                .form-item
                    height 50px
                    line-height 30px
                    font-size 19px
                    &::-webkit-input-placeholder
                        font-size 15px
                input.form-item:-moz-placeholder
                    font-size 15px
            .success
                .image
                    width 450px
                    height 450px
                    background-size 800px
                    margin-top 20px
                    .rocket
                        width 200px
                        top 55px
                        margin-left -100px
                    .cloud
                        &.front
                            width 800px
                            left 20px
                        &.middle
                            width 700px
                            left 50px
                            bottom 20px
                        &.back
                            width 550px
                            bottom 20px

    @keyframes rotate
        from
            transform rotate(0deg)
        to
            transform rotate(360deg)
</style>
